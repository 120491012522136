<svg id="eHpquLvdOY51" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
     viewBox="0 0 300 230" shape-rendering="geometricPrecision" text-rendering="geometricPrecision">
  <defs>
    <linearGradient id="eHpquLvdOY546-fill" x1="78.15" y1="18.21" x2="259.43" y2="272.73" spreadMethod="pad"
                    gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
      <stop id="eHpquLvdOY546-fill-0" offset="0%" stop-color="#def5ff"/>
      <stop id="eHpquLvdOY546-fill-1" offset="35%" stop-color="#cae3f4"/>
      <stop id="eHpquLvdOY546-fill-2" offset="79%" stop-color="#b6d0e8"/>
      <stop id="eHpquLvdOY546-fill-3" offset="100%" stop-color="#a8a8cf"/>
    </linearGradient>
    <linearGradient id="eHpquLvdOY547-fill" x1="170.83" y1="247.19" x2="272.29" y2="389.63" spreadMethod="pad"
                    gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
      <stop id="eHpquLvdOY547-fill-0" offset="0%" stop-color="#afcae3"/>
      <stop id="eHpquLvdOY547-fill-1" offset="86%" stop-color="#a3a3d4"/>
    </linearGradient>
    <linearGradient id="eHpquLvdOY549-fill" x1="-11.86" y1="-18.24" x2="15.47" y2="23.76" spreadMethod="pad"
                    gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
      <stop id="eHpquLvdOY549-fill-0" offset="9%" stop-color="#d18aff"/>
      <stop id="eHpquLvdOY549-fill-1" offset="87%" stop-color="#a864d4"/>
    </linearGradient>
    <linearGradient id="eHpquLvdOY550-fill" x1="-10.56" y1="-17.2" x2="12.77" y2="20.8" spreadMethod="pad"
                    gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
      <stop id="eHpquLvdOY550-fill-0" offset="12%" stop-color="#4dccbf"/>
      <stop id="eHpquLvdOY550-fill-1" offset="77%" stop-color="#38bfb1"/>
    </linearGradient>
    <linearGradient id="eHpquLvdOY551-fill" x1="-9.13" y1="-15.78" x2="12.87" y2="22.22" spreadMethod="pad"
                    gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
      <stop id="eHpquLvdOY551-fill-0" offset="12%" stop-color="#f2bd76"/>
      <stop id="eHpquLvdOY551-fill-1" offset="64%" stop-color="#f2a96d"/>
    </linearGradient>
    <linearGradient id="eHpquLvdOY552-fill" x1="-17.88" y1="0" x2="17.87" y2="0" spreadMethod="pad"
                    gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
      <stop id="eHpquLvdOY552-fill-0" offset="8%" stop-color="#f77460"/>
      <stop id="eHpquLvdOY552-fill-1" offset="97%" stop-color="#ff9788"/>
    </linearGradient>
    <linearGradient id="eHpquLvdOY553-fill" x1="38.01" y1="-20.67" x2="66.67" y2="54" spreadMethod="pad"
                    gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
      <stop id="eHpquLvdOY553-fill-0" offset="0%" stop-color="#b3bbe8"/>
      <stop id="eHpquLvdOY553-fill-1" offset="99%" stop-color="#7e90bd"/>
    </linearGradient>
    <linearGradient id="eHpquLvdOY554-fill" x1="19.62" y1="-42.69" x2="48.28" y2="31.97" spreadMethod="pad"
                    gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
      <stop id="eHpquLvdOY554-fill-0" offset="0%" stop-color="#b3bbe8"/>
      <stop id="eHpquLvdOY554-fill-1" offset="99%" stop-color="#7e90bd"/>
    </linearGradient>
    <linearGradient id="eHpquLvdOY557-fill" x1="245.066688" y1="-99.211015" x2="209.45679" y2="-31.272195"
                    spreadMethod="pad" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
      <stop id="eHpquLvdOY557-fill-0" offset="4%" stop-color="#ffa028"/>
      <stop id="eHpquLvdOY557-fill-1" offset="100%" stop-color="#f8b770"/>
    </linearGradient>
    <linearGradient id="eHpquLvdOY558-fill" x1="255.3" y1="224.31" x2="284.89" y2="357.04" spreadMethod="pad"
                    gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
      <stop id="eHpquLvdOY558-fill-0" offset="1%" stop-color="#ad7875"/>
      <stop id="eHpquLvdOY558-fill-1" offset="87%" stop-color="#7a5353"/>
    </linearGradient>
    <linearGradient id="eHpquLvdOY559-fill" x1="238.29" y1="220.26" x2="253.16" y2="282.32" spreadMethod="pad"
                    gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
      <stop id="eHpquLvdOY559-fill-0" offset="12%" stop-color="#ff7a32"/>
      <stop id="eHpquLvdOY559-fill-1" offset="84%" stop-color="#fc9d5d"/>
    </linearGradient>
    <linearGradient id="eHpquLvdOY561-fill" x1="193.957929" y1="-161.137213" x2="141.12291" y2="24.874297"
                    spreadMethod="pad" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
      <stop id="eHpquLvdOY561-fill-0" offset="0%" stop-color="#c9e5f0"/>
      <stop id="eHpquLvdOY561-fill-1" offset="42%" stop-color="#95bbda"/>
      <stop id="eHpquLvdOY561-fill-2" offset="63%" stop-color="#81aad1"/>
      <stop id="eHpquLvdOY561-fill-3" offset="100%" stop-color="#7d7dc4"/>
    </linearGradient>
    <linearGradient id="eHpquLvdOY562-fill" x1="0" y1="0.5" x2="1" y2="0.5" spreadMethod="pad"
                    gradientUnits="objectBoundingBox" gradientTransform="translate(0 0)">
      <stop id="eHpquLvdOY562-fill-0" offset="0%" stop-color="#d2dbed"/>
      <stop id="eHpquLvdOY562-fill-1" offset="100%" stop-color="#48b1ae"/>
    </linearGradient>
  </defs>
  <g transform="matrix(.534343 0 0 0.534339-33.420992-9.253981)">
    <g>
      <path
        d="M279.39,164.27c0-44.93-33.29-81.69-74-81.69v0c-18.77,0-36,7.83-49,20.67-19.52,4.2-34.38,23.29-34.38,46v104.75c.06592,21.852536,17.767448,39.549596,39.62,39.61h76.77c21.79,0,41-15.16,41-47v0Z"
        fill="#381717"/>
      <rect width="118.55" height="160.8" rx="48" ry="48" transform="translate(140.35 140.5)" fill="#0a0401"/>
    </g>
    <g>
      <path
        d="M304.82,368v-25.69c-.087883-30.742353-24.987647-55.642117-55.73-55.73h-3.52c-11.045695,0-20-8.954305-20-20v-20.05h-51.19v20.09c0,11.045695-8.954305,20-20,20h-3.52c-30.742353.087883-55.642117,24.987647-55.73,55.73v25.65c0,4.633669,3.756331,8.39,8.39,8.39h192.91c4.633669,0,8.39-3.756331,8.39-8.39Z"
        fill="#f7c299"/>
      <path
        d="M174.38,246.53v20.09c-.022782,4.66418-1.694734,9.169949-4.72,12.72c9.203861,5.01279,19.519592,7.632985,30,7.62h.75c10.480355.012558,20.795981-2.607611,30-7.62-3.025266-3.550051-4.697218-8.05582-4.72-12.72v-20.09Z"
        fill="#e3a57e"/>
      <g>
        <path
          d="M249.09,282.58h-3.52c-3.779989.004076-7.436827-1.343741-10.31-3.8-1.734239-1.449617-4.19884-1.634562-6.13-.46-7.41364,4.465635-15.905294,6.823659-24.56,6.82h-9.16c-8.654706.003659-17.14636-2.354365-24.56-6.82-1.93116-1.174562-4.395761-.989617-6.13.46-2.873173,2.456259-6.530011,3.804076-10.31,3.8h-3.52c-15.841381,0-31.03394,6.292964-42.235488,17.494512s-17.494512,26.394107-17.494512,42.235488v30c0,4.418278,3.581722,8,8,8h201.66c4.418278,0,8-3.581722,8-8v-30c0-15.841381-6.292964-31.03394-17.494512-42.235488s-26.394107-17.494512-42.235488-17.494512Z"
          fill="#911c22"/>
        <line x1="141.12" y1="348.39" x2="141.12" y2="371.97" fill="none" stroke="#6e0b0f" stroke-width="8"
              stroke-linecap="round" stroke-miterlimit="10"/>
        <circle r="4.82" transform="translate(232.36 293.81)" fill="#f2e3d5"/>
        <line x1="258.88" y1="348.39" x2="258.88" y2="371.97" fill="none" stroke="#6e0b0f" stroke-width="8"
              stroke-linecap="round" stroke-miterlimit="10"/>
      </g>
    </g>
    <g transform="translate(0 0.17351)">
      <circle id="eHpquLvdOY515" r="19.5" transform="translate(137.75 191.527058)" fill="#f7c299"/>
      <path
        d="M197.13,100.18h7.14c15.891772,0,31.132659,6.312982,42.369838,17.550162s17.550162,26.478066,17.550162,42.369838v41.27c0,34.860213-28.259787,63.12-63.12,63.12h-.75c-16.740465,0-32.795284-6.650124-44.63258-18.48742s-18.48742-27.892115-18.48742-44.63258v-41.26c0-15.891772,6.312982-31.132659,17.550162-42.369838s26.478066-17.550162,42.369838-17.550162Z"
        fill="#f7c299"/>
      <g id="eHpquLvdOY517">
        <path
          d="M207.36,205.13c-1.379305-2.340156-3.874208-3.796887-6.590126-3.847861s-5.263727,1.31111-6.729874,3.597861"
          fill="none" stroke="#cf946d" stroke-width="4" stroke-linecap="round" stroke-miterlimit="10"/>
        <g>
          <ellipse rx="6.88" ry="7.43" transform="translate(168.5 190.11)"/>
          <ellipse rx="6.88" ry="7.43" transform="translate(231.5 190.11)"/>
          <rect width="3.116413" height="1.219465" rx="0" ry="0"
                transform="matrix(4.772894 0 0 6.290397 160.980862 197.663123)" fill="#f7c299" stroke-width="0"/>
          <rect id="eHpquLvdOY523" width="3.116413" height="1.219465" rx="0" ry="0"
                transform="matrix(4.772894 0 0 6.290397 160.807352 174.759658)" fill="#f7c299" stroke-width="0"/>
          <rect width="3.116413" height="1.219465" rx="0" ry="0"
                transform="matrix(4.772894 0 0 6.290397 224.311904 197.663123)" fill="#f7c299" stroke-width="0"/>
          <rect id="eHpquLvdOY525" width="3.116413" height="1.219465" rx="0" ry="0"
                transform="matrix(4.772894 0 0 6.290397 223.444354 174.586169)" fill="#f7c299" stroke-width="0"/>
        </g>
        <g>
          <line x1="139.61" y1="176.01" x2="192.75" y2="175.95" fill="none" stroke="#240505" stroke-width="5"
                stroke-linecap="round" stroke-miterlimit="10"/>
          <path d="M147.28,176l1.75,15.2c1.09,9.48,8.1,16.56,16.4,16.56h6.31c8.6,0,15.63-8.1,15.63-18v-13.76Z"
                fill="none" stroke="#240505" stroke-width="5" stroke-linecap="round" stroke-miterlimit="10"/>
          <path d="M254.11,176l-1.75,15.2c-1.09,9.48-8.1,16.56-16.4,16.56h-6.31c-8.6,0-15.63-8.1-15.63-18v-13.76Z"
                fill="none" stroke="#240505" stroke-width="5" stroke-linecap="round" stroke-miterlimit="10"/>
          <line x1="261.78" y1="176.01" x2="208.64" y2="175.95" fill="none" stroke="#240505" stroke-width="5"
                stroke-linecap="round" stroke-miterlimit="10"/>
          <line x1="190.43" y1="185.3" x2="210.96" y2="185.3" fill="none" stroke="#240505" stroke-width="5"
                stroke-linecap="round" stroke-miterlimit="10"/>
        </g>
        <path d="M190.13,221c2.187309,3.714044,6.146374,6.025788,10.455912,6.10533s8.351214-2.084532,10.674088-5.71533"
              fill="none" stroke="#cf946d" stroke-width="4" stroke-linecap="round" stroke-miterlimit="10"/>
        <g id="eHpquLvdOY533">
          <line x1="219.84" y1="158.35" x2="243.17" y2="158.35" fill="none" stroke="#784242" stroke-width="8"
                stroke-linecap="round" stroke-miterlimit="10"/>
          <line x1="156.83" y1="158.35" x2="180.16" y2="158.35" fill="none" stroke="#784242" stroke-width="8"
                stroke-linecap="round" stroke-miterlimit="10"/>
        </g>
      </g>
      <g>
        <path
          d="M205.69,93.3h-7.17c-33.11,0-66,22.17-66,52.67v46.92c.010956,2.563572,2.086428,4.639044,4.65,4.65h.08c2.563572-.010956,4.639044-2.086428,4.65-4.65v-24c-.038781-30.538797,24.651358-55.345251,55.19-55.45h7.17c30.538642.104749,55.228781,24.911203,55.19,55.45v22.75h5v-31.84c.04-36.17-25.65-66.5-58.76-66.5Z"
          fill="#381717"/>
        <path d="M163.37,108.44c3.119155-3.25377,6.598167-6.142324,10.37-8.61" fill="none" stroke="#5e2626"
              stroke-width="6" stroke-linecap="round" stroke-miterlimit="10"/>
        <path
          d="M141.92,169.05v-.16c-.008028-19.376391,10.084525-37.355764,26.63-47.44-2.435281-1.074964-5.068019-1.630121-7.73-1.63-10.63,0-18.91,8.91-18.91,19.54v29.69Z"
          fill="#0a0401"/>
        <path
          d="M197.11,113.44h7.17c15.264916.00721,29.837633,6.369639,40.22,17.56-1.65122-18.362194-15.877658-33.090633-34.171578-35.377373s-35.708084,8.486623-41.828422,25.877373c8.60885-5.278331,18.511831-8.068196,28.61-8.06Z"
          fill="#0a0401"/>
        <path
          d="M279.39,290.85v-117.38c0-15.73-25.71-58.32-33.25-60.1-.795513,1.968197-1.291035,4.044672-1.47,6.16v241.15c.997213,11.116265,10.29913,19.6423,21.46,19.67h34.69c4.418278,0,8-3.581722,8-8v-30c.02136-21.172155-11.178081-40.770225-29.43-51.5Z"
          fill="#381717"/>
        <path d="M279.39,290.88v58.12c.027466,9.752995,7.927005,17.652534,17.68,17.68" fill="none" stroke="#5e2626"
              stroke-width="6" stroke-linecap="round" stroke-miterlimit="10"/>
        <line x1="258.88" y1="137.7" x2="258.88" y2="201.37" fill="none" stroke="#5e2626" stroke-width="6"
              stroke-linecap="round" stroke-miterlimit="10"/>
        <line x1="258.88" y1="234.51" x2="258.88" y2="266.85" fill="none" stroke="#5e2626" stroke-width="6"
              stroke-linecap="round" stroke-miterlimit="10"/>
      </g>
    </g>
  </g>
  <g transform="matrix(.549129 0 0 0.588198 99.27894-11.783429)">
    <rect width="263.16" height="186.46" rx="15.91" ry="15.91" transform="translate(68.42 117.54)"
          fill="url(#eHpquLvdOY546-fill)"/>
    <path
      d="M315.67,299h-231.34c-4.219594,0-8.266365-1.676227-11.250069-4.659931s-4.659931-7.030475-4.659931-11.250069v5c0,4.219594,1.676227,8.266365,4.659931,11.250069s7.030475,4.659931,11.250069,4.659931h231.34c4.219594,0,8.266365-1.676227,11.250069-4.659931s4.659931-7.030475,4.659931-11.250069v-5c0,4.219594-1.676227,8.266365-4.659931,11.250069s-7.030475,4.659931-11.250069,4.659931Z"
      fill="url(#eHpquLvdOY547-fill)"/>
    <polyline id="eHpquLvdOY548" points="121.23,228.99 195.95,259.27 225.9,182.49 290,202.58"
              transform="translate(.324825-.303252)" fill="none" stroke="#8c99c8" stroke-width="14"
              stroke-miterlimit="10"/>
    <circle id="eHpquLvdOY549" r="17.87" transform="translate(121.23 228.99)" fill="url(#eHpquLvdOY549-fill)"/>
    <circle id="eHpquLvdOY550" r="17.87" transform="translate(193.67622 257.450483)" fill="url(#eHpquLvdOY550-fill)"/>
    <circle r="17.87" transform="translate(225.9 182.49)" fill="url(#eHpquLvdOY551-fill)"/>
    <circle id="eHpquLvdOY552" r="17.87" transform="translate(290 202.58)" fill="url(#eHpquLvdOY552-fill)"/>
    <rect width="97.13" height="13.66" rx="6.83" ry="6.83" transform="translate(94.822307 144.88581)"
          fill="url(#eHpquLvdOY553-fill)"/>
    <rect width="77.26" height="13.66" rx="6.83" ry="6.83" transform="translate(93.61 175.1)"
          fill="url(#eHpquLvdOY554-fill)"/>
  </g>
  <g id="eHpquLvdOY555" transform="matrix(.238482 0 0 0.238478 128.052209 106.128609)">
    <g>
      <rect width="28.8" height="43.32" rx="0" ry="0"
            transform="matrix(.707107-.707107 0.707107 0.707107 206.834272 230.61471)" fill="url(#eHpquLvdOY557-fill)"/>
      <path
        d="M217.15,260l51.21,51.21c10.33,10.33,27,11.41,38.12,1.95c5.916399-5.047335,9.460509-12.332859,9.779368-20.103167s-2.616406-15.321718-8.099368-20.836833l-51.61-51.61c-.600299-.601499-1.4152-.939519-2.265-.939519s-1.664701.33802-2.265.939519l-34.87,34.87c-1.246027,1.249048-1.246027,3.270952,0,4.52Z"
        fill="url(#eHpquLvdOY558-fill)"/>
      <path
        d="M234.18,277l39.39-39.39-17-17c-.600299-.601499-1.4152-.939519-2.265-.939519s-1.664701.33802-2.265.939519l-34.87,34.87c-.601499.600299-.939519,1.4152-.939519,2.265s.33802,1.664701.939519,2.265Z"
        fill="url(#eHpquLvdOY559-fill)"/>
    </g>
    <g>
      <path
        d="M106.89664,225.68709c-31.69495-31.69495-31.69495-83.08262,0-114.77757s83.08262-31.69495,114.77757,0s31.69495,83.08262,0,114.77757-83.08262,31.69495-114.77757,0ZM207.87593,211.2904c23.74388-23.74388,23.74388-62.24031,0-85.98418s-62.24031-23.74388-85.98418,0-23.74388,62.24031,0,85.98418s62.24031,23.74388,85.98418,0Z"
        fill="url(#eHpquLvdOY561-fill)"/>
      <ellipse rx="16.636182" ry="16.636182" transform="matrix(3.781728 0 0 3.781731 164.285423 168.29831)"
               opacity="0.86" fill="url(#eHpquLvdOY562-fill)" stroke-width="0"/>
    </g>
  </g>
</svg>
