import { Amplify, fetchAuthSession } from '@aws-amplify/core';
import { assertTokenProviderConfig, AuthAction } from '@aws-amplify/core/internals/utils';
import { AuthValidationErrorCode } from '../../../errors/types/validation.mjs';
import { assertValidationError } from '../../../errors/utils/assertValidationError.mjs';
import { verifySoftwareToken } from '../utils/clients/CognitoIdentityProvider/index.mjs';
import { getRegion } from '../utils/clients/CognitoIdentityProvider/utils.mjs';
import { assertAuthTokens } from '../utils/types.mjs';
import { getAuthUserAgentValue } from '../../../utils/getAuthUserAgentValue.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/**
 * Verifies an OTP code retrieved from an associated authentication app.
 *
 * @param input - The VerifyTOTPSetupInput
 * @throws  -{@link VerifySoftwareTokenException }:
 * Thrown due to an invalid MFA token.
 * @throws  -{@link AuthValidationErrorCode }:
 * Thrown when `code` is not defined.
 * @throws AuthTokenConfigException - Thrown when the token provider config is invalid.
 */
async function verifyTOTPSetup(input) {
  const authConfig = Amplify.getConfig().Auth?.Cognito;
  assertTokenProviderConfig(authConfig);
  const {
    code,
    options
  } = input;
  assertValidationError(!!code, AuthValidationErrorCode.EmptyVerifyTOTPSetupCode);
  const {
    tokens
  } = await fetchAuthSession({
    forceRefresh: false
  });
  assertAuthTokens(tokens);
  await verifySoftwareToken({
    region: getRegion(authConfig.userPoolId),
    userAgentValue: getAuthUserAgentValue(AuthAction.VerifyTOTPSetup)
  }, {
    AccessToken: tokens.accessToken.toString(),
    UserCode: code,
    FriendlyDeviceName: options?.friendlyDeviceName
  });
}
export { verifyTOTPSetup };
