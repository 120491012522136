/* tslint:disable */
/* eslint-disable */
export enum PortalMessageEvent {
  MeasurementProfileToDraft = 'MeasurementProfileToDraft',
  MeasurementProfileToPending = 'MeasurementProfileToPending',
  MeasurementProfileToQuoting = 'MeasurementProfileToQuoting',
  MeasurementProfileToActive = 'MeasurementProfileToActive',
  AccountingSyncStarted = 'AccountingSyncStarted',
  AccountingSyncProgress = 'AccountingSyncProgress',
  AccountingSyncDone = 'AccountingSyncDone',
  AccountingSyncError = 'AccountingSyncError',
  AccountingCsvFileProcessed = 'AccountingCsvFileProcessed',
  AccountingCsvFileProcessFailed = 'AccountingCsvFileProcessFailed',
  AccountingCsvFileProcessing = 'AccountingCsvFileProcessing',
  AccountingCsvFileProcessingProgress = 'AccountingCsvFileProcessingProgress',
  AccountingMappingProgress = 'AccountingMappingProgress',
  UserChangedDefaultCompany = 'UserChangedDefaultCompany',
  CompanyStatusUpdated = 'CompanyStatusUpdated',
  CollectionInstanceProcessFailed = 'CollectionInstanceProcessFailed',
  AppNotificationCreated = 'AppNotificationCreated'
}
