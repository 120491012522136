/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { create } from '../fn/pn-l-tag-relations/create';
import { Create$Params } from '../fn/pn-l-tag-relations/create';
import { delete$ } from '../fn/pn-l-tag-relations/delete';
import { Delete$Params } from '../fn/pn-l-tag-relations/delete';
import { get } from '../fn/pn-l-tag-relations/get';
import { Get$Params } from '../fn/pn-l-tag-relations/get';
import { IdResponse } from '../models/id-response';
import { PnLTagRelationResponse } from '../models/pn-l-tag-relation-response';

@Injectable({ providedIn: 'root' })
export class PnLTagRelationsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1PnLTagRelationsGetGet()` */
  static readonly V1PnLTagRelationsGetGetPath = '/v1/pn-ltag-relations/get';

  /**
   * Get PnL Tag Relations.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Response(params?: Get$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PnLTagRelationResponse>>> {
    return get(this.http, this.rootUrl, params, context);
  }

  /**
   * Get PnL Tag Relations.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `get$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get(params?: Get$Params, context?: HttpContext): Observable<Array<PnLTagRelationResponse>> {
    return this.get$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PnLTagRelationResponse>>): Array<PnLTagRelationResponse> => r.body)
    );
  }

  /** Path part for operation `v1PnLTagRelationsCreatePost()` */
  static readonly V1PnLTagRelationsCreatePostPath = '/v1/pn-ltag-relations/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create$Response(params?: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<IdResponse>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create(params?: Create$Params, context?: HttpContext): Observable<IdResponse> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<IdResponse>): IdResponse => r.body)
    );
  }

  /** Path part for operation `v1PnLTagRelationsDeleteDelete()` */
  static readonly V1PnLTagRelationsDeleteDeletePath = '/v1/pn-ltag-relations/delete';

  /**
   * To Delete a PnL Tag relation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  delete$Response(params?: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * To Delete a PnL Tag relation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  delete(params?: Delete$Params, context?: HttpContext): Observable<void> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
