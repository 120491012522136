/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createFromHostedPage } from '../fn/payment/create-from-hosted-page';
import { CreateFromHostedPage$Params } from '../fn/payment/create-from-hosted-page';
import { estimate } from '../fn/payment/estimate';
import { Estimate$Params } from '../fn/payment/estimate';
import { EstimateResult } from '../models/estimate-result';
import { getHostedPage } from '../fn/payment/get-hosted-page';
import { GetHostedPage$Params } from '../fn/payment/get-hosted-page';
import { getPortalToken } from '../fn/payment/get-portal-token';
import { GetPortalToken$Params } from '../fn/payment/get-portal-token';
import { HostedPageResult } from '../models/hosted-page-result';
import { payQuote } from '../fn/payment/pay-quote';
import { PayQuote$Params } from '../fn/payment/pay-quote';
import { PayQuoteResponse } from '../models/pay-quote-response';
import { PortalTokenResult } from '../models/portal-token-result';
import { quotePaymentDetails } from '../fn/payment/quote-payment-details';
import { QuotePaymentDetails$Params } from '../fn/payment/quote-payment-details';
import { QuotePaymentDetailsResponse } from '../models/quote-payment-details-response';
import { syncCompany } from '../fn/payment/sync-company';
import { SyncCompany$Params } from '../fn/payment/sync-company';

@Injectable({ providedIn: 'root' })
export class PaymentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1PaymentGetPortalTokenGet()` */
  static readonly V1PaymentGetPortalTokenGetPath = '/v1/payment/get-portal-token';

  /**
   * Get the ChargeBee token for the user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPortalToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPortalToken$Response(params?: GetPortalToken$Params, context?: HttpContext): Observable<StrictHttpResponse<PortalTokenResult>> {
    return getPortalToken(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the ChargeBee token for the user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPortalToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPortalToken(params?: GetPortalToken$Params, context?: HttpContext): Observable<PortalTokenResult> {
    return this.getPortalToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<PortalTokenResult>): PortalTokenResult => r.body)
    );
  }

  /** Path part for operation `v1PaymentSyncCompanyPost()` */
  static readonly V1PaymentSyncCompanyPostPath = '/v1/payment/sync-company';

  /**
   * To Force Update company in customer portal.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `syncCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  syncCompany$Response(params?: SyncCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return syncCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * To Force Update company in customer portal.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `syncCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  syncCompany(params?: SyncCompany$Params, context?: HttpContext): Observable<void> {
    return this.syncCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1PaymentGetHostedPagePost()` */
  static readonly V1PaymentGetHostedPagePostPath = '/v1/payment/get-hosted-page';

  /**
   * Get token for new companies.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHostedPage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getHostedPage$Response(params?: GetHostedPage$Params, context?: HttpContext): Observable<StrictHttpResponse<HostedPageResult>> {
    return getHostedPage(this.http, this.rootUrl, params, context);
  }

  /**
   * Get token for new companies.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHostedPage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getHostedPage(params?: GetHostedPage$Params, context?: HttpContext): Observable<HostedPageResult> {
    return this.getHostedPage$Response(params, context).pipe(
      map((r: StrictHttpResponse<HostedPageResult>): HostedPageResult => r.body)
    );
  }

  /** Path part for operation `v1PaymentCreateFromHostedPagePost()` */
  static readonly V1PaymentCreateFromHostedPagePostPath = '/v1/payment/create-from-hosted-page';

  /**
   * Create company using HostedPage from ChargeBee.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createFromHostedPage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createFromHostedPage$Response(params?: CreateFromHostedPage$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createFromHostedPage(this.http, this.rootUrl, params, context);
  }

  /**
   * Create company using HostedPage from ChargeBee.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createFromHostedPage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createFromHostedPage(params?: CreateFromHostedPage$Params, context?: HttpContext): Observable<void> {
    return this.createFromHostedPage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1PaymentQuotePaymentDetailsPost()` */
  static readonly V1PaymentQuotePaymentDetailsPostPath = '/v1/payment/quote-payment-details';

  /**
   * Get quote payment details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `quotePaymentDetails()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  quotePaymentDetails$Response(params?: QuotePaymentDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<QuotePaymentDetailsResponse>> {
    return quotePaymentDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Get quote payment details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `quotePaymentDetails$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  quotePaymentDetails(params?: QuotePaymentDetails$Params, context?: HttpContext): Observable<QuotePaymentDetailsResponse> {
    return this.quotePaymentDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<QuotePaymentDetailsResponse>): QuotePaymentDetailsResponse => r.body)
    );
  }

  /** Path part for operation `v1PaymentEstimatePost()` */
  static readonly V1PaymentEstimatePostPath = '/v1/payment/estimate';

  /**
   * Estimate the price of the quote for the MP.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `estimate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  estimate$Response(params?: Estimate$Params, context?: HttpContext): Observable<StrictHttpResponse<EstimateResult>> {
    return estimate(this.http, this.rootUrl, params, context);
  }

  /**
   * Estimate the price of the quote for the MP.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `estimate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  estimate(params?: Estimate$Params, context?: HttpContext): Observable<EstimateResult> {
    return this.estimate$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstimateResult>): EstimateResult => r.body)
    );
  }

  /** Path part for operation `v1PaymentPayQuotePost()` */
  static readonly V1PaymentPayQuotePostPath = '/v1/payment/pay-quote';

  /**
   * Pay a measurement profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `payQuote()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  payQuote$Response(params?: PayQuote$Params, context?: HttpContext): Observable<StrictHttpResponse<PayQuoteResponse>> {
    return payQuote(this.http, this.rootUrl, params, context);
  }

  /**
   * Pay a measurement profile.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `payQuote$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  payQuote(params?: PayQuote$Params, context?: HttpContext): Observable<PayQuoteResponse> {
    return this.payQuote$Response(params, context).pipe(
      map((r: StrictHttpResponse<PayQuoteResponse>): PayQuoteResponse => r.body)
    );
  }

}
