import {Injectable, NgZone} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NewVersionDialogComponent} from '../components/new-version-dialog/new-version-dialog.component';
import {
  ErrorMessageDialogComponent,
  ErrorMessageDialogInput
} from '../components/error-message-dialog/error-message-dialog.component';
import {filterCognitoAwsMessage} from '../../../utils/function-utils';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private zone: NgZone,
    private snackBar: MatSnackBar) {
  }

  showSuccess(message: string, duration = 5000): void {
    this.zone.run(() => {
      this.snackBar.open(message, 'X', {
        duration: duration,
        panelClass: ['snack-message']
      });
    });
  }

  showError(message: ErrorMessageDialogInput | string): void {
    const input = (typeof message == 'string') ? {title: filterCognitoAwsMessage(message), details: []}
      : {title: filterCognitoAwsMessage(message.title), details: message.details};
    this.zone.run(() => {
      // In the third, we send in the css class for the snack bar.
      this.snackBar.openFromComponent(ErrorMessageDialogComponent, {
        data: input,
        duration: 10000,
        panelClass: ['error']
      });
    });
  }

  showUpdate() {
    this.zone.run(() => {
      this.snackBar.openFromComponent(NewVersionDialogComponent);
    });
  }
}
