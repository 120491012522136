/* tslint:disable */
/* eslint-disable */

/**
 * Represents the filter options for mapping in accounting integrations.
 */
export enum MappingFilterOptions {
  All = 'All',
  JustUnmapped = 'JustUnmapped',
  JustMapped = 'JustMapped'
}
