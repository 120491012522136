/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ChartProjectionItemResult } from '../models/chart-projection-item-result';
import { ChartProjectionResponse } from '../models/chart-projection-response';
import { createEmissionProjection } from '../fn/chart-projection/create-emission-projection';
import { CreateEmissionProjection$Params } from '../fn/chart-projection/create-emission-projection';
import { createTargetProjection } from '../fn/chart-projection/create-target-projection';
import { CreateTargetProjection$Params } from '../fn/chart-projection/create-target-projection';
import { currents } from '../fn/chart-projection/currents';
import { Currents$Params } from '../fn/chart-projection/currents';
import { delete$ } from '../fn/chart-projection/delete';
import { Delete$Params } from '../fn/chart-projection/delete';
import { getById } from '../fn/chart-projection/get-by-id';
import { GetById$Params } from '../fn/chart-projection/get-by-id';
import { getInitialProjection } from '../fn/chart-projection/get-initial-projection';
import { GetInitialProjection$Params } from '../fn/chart-projection/get-initial-projection';
import { toggleDisplay } from '../fn/chart-projection/toggle-display';
import { ToggleDisplay$Params } from '../fn/chart-projection/toggle-display';
import { updateEmissionProjection } from '../fn/chart-projection/update-emission-projection';
import { UpdateEmissionProjection$Params } from '../fn/chart-projection/update-emission-projection';
import { updateTargetProjection } from '../fn/chart-projection/update-target-projection';
import { UpdateTargetProjection$Params } from '../fn/chart-projection/update-target-projection';

@Injectable({ providedIn: 'root' })
export class ChartProjectionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1ChartProjectionCurrentsPost()` */
  static readonly V1ChartProjectionCurrentsPostPath = '/v1/chart-projection/currents';

  /**
   * API endpoint for
   *     getting all the emission projection profiles
   *     getting all target projection profiles
   * of the logged user's default company
   * If there are no any already created emission profiles, this endpoint will create the default emissions profile based on default data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `currents()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  currents$Response(params?: Currents$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChartProjectionItemResult>>> {
    return currents(this.http, this.rootUrl, params, context);
  }

  /**
   * API endpoint for
   *     getting all the emission projection profiles
   *     getting all target projection profiles
   * of the logged user's default company
   * If there are no any already created emission profiles, this endpoint will create the default emissions profile based on default data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `currents$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  currents(params?: Currents$Params, context?: HttpContext): Observable<Array<ChartProjectionItemResult>> {
    return this.currents$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChartProjectionItemResult>>): Array<ChartProjectionItemResult> => r.body)
    );
  }

  /** Path part for operation `v1ChartProjectionGetInitialProjectionPost()` */
  static readonly V1ChartProjectionGetInitialProjectionPostPath = '/v1/chart-projection/get-initial-projection';

  /**
   * API endpoint for fetching initial default data for projection profile
   *             
   * If the projection type type is Emission
   *     Name = Company Name + 'emissions' -> Ex: Est2026 emissions
   *     BaseLineMeasurementProfileId = MP id of the first Baseline Profile Options
   *     TotalTonsCO2PerEmployee = Emission intensity of the most recent 'active/pending' and 'default/measure only' profile
   *     BaselineOptions = List of possible baseline options (If needed multiple non linked path yet to be handled here) -> Ex: 'Year 1 (2020)','Year 2 (2021)',''Year 3 (2022)
   *     TotalTonsCO2PerEmployee = Total TonsCO2 Per Employee of the latest profile
   *     DefaultFteByTargetYears = Default option list of Target years and respective FTE by the target year
   *         TargetYear = 1st year of the next three consecutive decades upto defined default decade count (pre set to 3)
   *             Ex: if baseline year is 2023 -> options will be 2030,2040,2050
   *         FteByTargetYear = Apply a 15% year on year growth to total employee count from most recent MP per year until target year ->
   *             Ex: 2023:50FTE --> 2025:66FTE
   *                 = 50*(115/100)^(2025-2023)
   *                 = 66.125
   *                 â‰ˆ 66
   *             
   * If the projection type is Target
   *     Name = Default to 'Net Zero'
   *     BaseLineMeasurementProfileId = MP id of the first Baseline Profile Options
   *     BaselineOptions = List of possible baseline options (If needed multiple non linked path yet to be handled here) -> Ex: 'Year 1 (2020)','Year 2 (2021)',''Year 3 (2022)
   *     ReductionRate = System default value
   *         90% of the total emission of the baseline year need to be reduced at the target year.
   *         an annual reduction rate will be back calculated based on this.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInitialProjection()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getInitialProjection$Response(params?: GetInitialProjection$Params, context?: HttpContext): Observable<StrictHttpResponse<ChartProjectionResponse>> {
    return getInitialProjection(this.http, this.rootUrl, params, context);
  }

  /**
   * API endpoint for fetching initial default data for projection profile
   *             
   * If the projection type type is Emission
   *     Name = Company Name + 'emissions' -> Ex: Est2026 emissions
   *     BaseLineMeasurementProfileId = MP id of the first Baseline Profile Options
   *     TotalTonsCO2PerEmployee = Emission intensity of the most recent 'active/pending' and 'default/measure only' profile
   *     BaselineOptions = List of possible baseline options (If needed multiple non linked path yet to be handled here) -> Ex: 'Year 1 (2020)','Year 2 (2021)',''Year 3 (2022)
   *     TotalTonsCO2PerEmployee = Total TonsCO2 Per Employee of the latest profile
   *     DefaultFteByTargetYears = Default option list of Target years and respective FTE by the target year
   *         TargetYear = 1st year of the next three consecutive decades upto defined default decade count (pre set to 3)
   *             Ex: if baseline year is 2023 -> options will be 2030,2040,2050
   *         FteByTargetYear = Apply a 15% year on year growth to total employee count from most recent MP per year until target year ->
   *             Ex: 2023:50FTE --> 2025:66FTE
   *                 = 50*(115/100)^(2025-2023)
   *                 = 66.125
   *                 â‰ˆ 66
   *             
   * If the projection type is Target
   *     Name = Default to 'Net Zero'
   *     BaseLineMeasurementProfileId = MP id of the first Baseline Profile Options
   *     BaselineOptions = List of possible baseline options (If needed multiple non linked path yet to be handled here) -> Ex: 'Year 1 (2020)','Year 2 (2021)',''Year 3 (2022)
   *     ReductionRate = System default value
   *         90% of the total emission of the baseline year need to be reduced at the target year.
   *         an annual reduction rate will be back calculated based on this.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInitialProjection$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getInitialProjection(params?: GetInitialProjection$Params, context?: HttpContext): Observable<ChartProjectionResponse> {
    return this.getInitialProjection$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChartProjectionResponse>): ChartProjectionResponse => r.body)
    );
  }

  /** Path part for operation `v1ChartProjectionGetByIdPost()` */
  static readonly V1ChartProjectionGetByIdPostPath = '/v1/chart-projection/get-by-id';

  /**
   * API endpoint for getting the emission projection profile by emission projection profile id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getById$Response(params?: GetById$Params, context?: HttpContext): Observable<StrictHttpResponse<ChartProjectionResponse>> {
    return getById(this.http, this.rootUrl, params, context);
  }

  /**
   * API endpoint for getting the emission projection profile by emission projection profile id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getById(params?: GetById$Params, context?: HttpContext): Observable<ChartProjectionResponse> {
    return this.getById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChartProjectionResponse>): ChartProjectionResponse => r.body)
    );
  }

  /** Path part for operation `v1ChartProjectionCreateEmissionProjectionPost()` */
  static readonly V1ChartProjectionCreateEmissionProjectionPostPath = '/v1/chart-projection/create-emission-projection';

  /**
   * Create emission projection.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createEmissionProjection()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createEmissionProjection$Response(params?: CreateEmissionProjection$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createEmissionProjection(this.http, this.rootUrl, params, context);
  }

  /**
   * Create emission projection.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createEmissionProjection$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createEmissionProjection(params?: CreateEmissionProjection$Params, context?: HttpContext): Observable<void> {
    return this.createEmissionProjection$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1ChartProjectionUpdateEmissionProjectionPut()` */
  static readonly V1ChartProjectionUpdateEmissionProjectionPutPath = '/v1/chart-projection/update-emission-projection';

  /**
   * Update emission projection.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateEmissionProjection()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateEmissionProjection$Response(params?: UpdateEmissionProjection$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateEmissionProjection(this.http, this.rootUrl, params, context);
  }

  /**
   * Update emission projection.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateEmissionProjection$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateEmissionProjection(params?: UpdateEmissionProjection$Params, context?: HttpContext): Observable<void> {
    return this.updateEmissionProjection$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1ChartProjectionCreateTargetProjectionPost()` */
  static readonly V1ChartProjectionCreateTargetProjectionPostPath = '/v1/chart-projection/create-target-projection';

  /**
   * Create target projection.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTargetProjection()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTargetProjection$Response(params?: CreateTargetProjection$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createTargetProjection(this.http, this.rootUrl, params, context);
  }

  /**
   * Create target projection.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTargetProjection$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTargetProjection(params?: CreateTargetProjection$Params, context?: HttpContext): Observable<void> {
    return this.createTargetProjection$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1ChartProjectionUpdateTargetProjectionPut()` */
  static readonly V1ChartProjectionUpdateTargetProjectionPutPath = '/v1/chart-projection/update-target-projection';

  /**
   * Update target projection.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTargetProjection()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTargetProjection$Response(params?: UpdateTargetProjection$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateTargetProjection(this.http, this.rootUrl, params, context);
  }

  /**
   * Update target projection.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTargetProjection$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTargetProjection(params?: UpdateTargetProjection$Params, context?: HttpContext): Observable<void> {
    return this.updateTargetProjection$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1ChartProjectionDeleteDelete()` */
  static readonly V1ChartProjectionDeleteDeletePath = '/v1/chart-projection/delete';

  /**
   * Delete a projection profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  delete$Response(params?: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a projection profile.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  delete(params?: Delete$Params, context?: HttpContext): Observable<void> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1ChartProjectionToggleDisplayPost()` */
  static readonly V1ChartProjectionToggleDisplayPostPath = '/v1/chart-projection/toggle-display';

  /**
   * Toggle display projection.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `toggleDisplay()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  toggleDisplay$Response(params?: ToggleDisplay$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return toggleDisplay(this.http, this.rootUrl, params, context);
  }

  /**
   * Toggle display projection.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `toggleDisplay$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  toggleDisplay(params?: ToggleDisplay$Params, context?: HttpContext): Observable<void> {
    return this.toggleDisplay$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
