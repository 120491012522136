<mat-dialog-content>
  <mat-card class="margin-bottom-16">
    <img src="assets/portal/images/reduce/reduce-welcome.png" alt="Welcome reduce">
  </mat-card>
  <h4>Welcome to Reduce!</h4>
  <p>
    This is where you can organise reduction initiatives into a decarbonisation plan for your company. To begin, click
    on the <strong>initiatives bank</strong>, where you’ll see a curated selection of decarbonisation initiatives
    created by the Trace
    team to help you get started.
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <mat-checkbox #dontShowAgain>Don't show again</mat-checkbox>
  <span fxFlex></span>
  <button mat-raised-button color="primary" [matDialogClose]="dontShowAgain.checked">Got it</button>
</mat-dialog-actions>
