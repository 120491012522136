<div mat-dialog-title>
  <h2>Initiatives bank</h2>
  <span fxFlex></span>
  <mat-icon class="close" fontIcon="icon-cancel" matDialogClose="true"></mat-icon>
</div>
<mat-dialog-content>
  <mat-expansion-panel expanded>
    <mat-expansion-panel-header fxLayout="row" fxFlexAlign=" center">
      Filter Initiatives
    </mat-expansion-panel-header>
    <p>Choose from some of our suggested initiatives or use them as inspiration for your own.</p>
    @if (categories.length > 0) {
      <div
        fxLayout="row wrap"
        fxLayoutGap="8px">
        <div fxLayout="row"
          fxLayoutAlign="center center"
          class="c-chip clickable margin-bottom-16"
          [ngClass]="{'selected': isAllCategoriesSelected}"
          (click)="selectAll()">
          <span class="label">All</span>
        </div>
        @for (category of categories; track category.id) {
          <div (click)="selectCategory(category)"
            fxLayout="row"
            fxLayoutAlign="center center"
            class="c-chip clickable margin-bottom-16"
            [ngClass]="{'selected': category.selected}"
            >
            <span class="label">{{category.name}}</span>
          </div>
        }
      </div>
    }
    <mat-form-field appearance="outline" fxFlexFill>
      <mat-label>Search</mat-label>
      <input matInput [formControl]="name">
      <mat-icon matIconPrefix fontIcon="icon-search"></mat-icon>
    </mat-form-field>
  </mat-expansion-panel>
  <br>
    <app-table
      [tableOptions]="tableOptions"
      [dataSourceMethodPaging]="dataSourcePagingMethod"
      [templateRow]="templateRow"
      [enableDisplayIdColumn]="false"
      [sortActive]="'name'"
      [enableRowClick]="true"
      (rowClickEvent)="viewDetails($event)">
      <ng-template #templateRow let-element="element" let-column="column">
        <div>
          @switch (column.id) {
            @case ('category') {
              <div>
                <div class="category-color-display" [style.background]="element.baseColorHex"></div>
                <div>{{ element.name }}</div>
              </div>
            }
            @case ('impact') {
              <div fxLayoutAlign="start stretch">
                <mat-icon class="svg-icon-accent-color"
                [svgIcon]="ImpactIconMapping[element]"></mat-icon>
              </div>
            }
            @case ('effort') {
              <div fxLayoutAlign="start stretch">
                <mat-icon class="svg-icon-primary-color"
                [svgIcon]="EffortIconMapping[element]"></mat-icon>
              </div>
            }
          }
        </div>
      </ng-template>
    </app-table>
  </mat-dialog-content>
