/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { create } from '../fn/control-instance/create';
import { Create$Params } from '../fn/control-instance/create';
import { createRange } from '../fn/control-instance/create-range';
import { CreateRange$Params } from '../fn/control-instance/create-range';
import { delete$ } from '../fn/control-instance/delete';
import { Delete$Params } from '../fn/control-instance/delete';
import { deleteList } from '../fn/control-instance/delete-list';
import { DeleteList$Params } from '../fn/control-instance/delete-list';
import { getAllByCollectionInstanceStepId } from '../fn/control-instance/get-all-by-collection-instance-step-id';
import { GetAllByCollectionInstanceStepId$Params } from '../fn/control-instance/get-all-by-collection-instance-step-id';
import { GetAllControlInstanceResponse } from '../models/get-all-control-instance-response';
import { getSpending } from '../fn/control-instance/get-spending';
import { GetSpending$Params } from '../fn/control-instance/get-spending';
import { getSpendingDetail } from '../fn/control-instance/get-spending-detail';
import { GetSpendingDetail$Params } from '../fn/control-instance/get-spending-detail';
import { IdResponse } from '../models/id-response';
import { SpendingDetailResult } from '../models/spending-detail-result';
import { SpendingResult } from '../models/spending-result';
import { update } from '../fn/control-instance/update';
import { Update$Params } from '../fn/control-instance/update';
import { updateRequiredStatus } from '../fn/control-instance/update-required-status';
import { UpdateRequiredStatus$Params } from '../fn/control-instance/update-required-status';

@Injectable({ providedIn: 'root' })
export class ControlInstanceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1ControlInstanceGetAllByCollectionInstanceStepIdPost()` */
  static readonly V1ControlInstanceGetAllByCollectionInstanceStepIdPostPath = '/v1/control-instance/get-all-by-collection-instance-step-id';

  /**
   * Get All control components.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllByCollectionInstanceStepId()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllByCollectionInstanceStepId$Response(params?: GetAllByCollectionInstanceStepId$Params, context?: HttpContext): Observable<StrictHttpResponse<GetAllControlInstanceResponse>> {
    return getAllByCollectionInstanceStepId(this.http, this.rootUrl, params, context);
  }

  /**
   * Get All control components.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllByCollectionInstanceStepId$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllByCollectionInstanceStepId(params?: GetAllByCollectionInstanceStepId$Params, context?: HttpContext): Observable<GetAllControlInstanceResponse> {
    return this.getAllByCollectionInstanceStepId$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetAllControlInstanceResponse>): GetAllControlInstanceResponse => r.body)
    );
  }

  /** Path part for operation `v1ControlInstanceUpdatePut()` */
  static readonly V1ControlInstanceUpdatePutPath = '/v1/control-instance/update';

  /**
   * Update a Control Instance Value.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  update$Response(params?: Update$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return update(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a Control Instance Value.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  update(params?: Update$Params, context?: HttpContext): Observable<void> {
    return this.update$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1ControlInstanceCreatePost()` */
  static readonly V1ControlInstanceCreatePostPath = '/v1/control-instance/create';

  /**
   * Create Control instances based on a Control Type Template.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create$Response(params?: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<IdResponse>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * Create Control instances based on a Control Type Template.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create(params?: Create$Params, context?: HttpContext): Observable<IdResponse> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<IdResponse>): IdResponse => r.body)
    );
  }

  /** Path part for operation `v1ControlInstanceCreateRangePost()` */
  static readonly V1ControlInstanceCreateRangePostPath = '/v1/control-instance/create-range';

  /**
   * Create Control instances based on a Control Type Template.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRange()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRange$Response(params?: CreateRange$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<IdResponse>>> {
    return createRange(this.http, this.rootUrl, params, context);
  }

  /**
   * Create Control instances based on a Control Type Template.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createRange$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRange(params?: CreateRange$Params, context?: HttpContext): Observable<Array<IdResponse>> {
    return this.createRange$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IdResponse>>): Array<IdResponse> => r.body)
    );
  }

  /** Path part for operation `v1ControlInstanceUpdateRequiredStatusPut()` */
  static readonly V1ControlInstanceUpdateRequiredStatusPutPath = '/v1/control-instance/update-required-status';

  /**
   * Update required status of controls.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRequiredStatus()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateRequiredStatus$Response(params?: UpdateRequiredStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateRequiredStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Update required status of controls.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateRequiredStatus$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateRequiredStatus(params?: UpdateRequiredStatus$Params, context?: HttpContext): Observable<void> {
    return this.updateRequiredStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1ControlInstanceDeleteDelete()` */
  static readonly V1ControlInstanceDeleteDeletePath = '/v1/control-instance/delete';

  /**
   * Delete a control instance.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  delete$Response(params?: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a control instance.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  delete(params?: Delete$Params, context?: HttpContext): Observable<void> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1ControlInstanceDeleteListDelete()` */
  static readonly V1ControlInstanceDeleteListDeletePath = '/v1/control-instance/delete-list';

  /**
   * Delete a list of control instance.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteList()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteList$Response(params?: DeleteList$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteList(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a list of control instance.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteList$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteList(params?: DeleteList$Params, context?: HttpContext): Observable<void> {
    return this.deleteList$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1ControlInstanceGetSpendingPost()` */
  static readonly V1ControlInstanceGetSpendingPostPath = '/v1/control-instance/get-spending';

  /**
   * Get Spending data by a Control Instance Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSpending()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getSpending$Response(params?: GetSpending$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SpendingResult>>> {
    return getSpending(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Spending data by a Control Instance Id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSpending$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getSpending(params?: GetSpending$Params, context?: HttpContext): Observable<Array<SpendingResult>> {
    return this.getSpending$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SpendingResult>>): Array<SpendingResult> => r.body)
    );
  }

  /** Path part for operation `v1ControlInstanceGetSpendingDetailPost()` */
  static readonly V1ControlInstanceGetSpendingDetailPostPath = '/v1/control-instance/get-spending-detail';

  /**
   * Get the detail of spending grouped by suppliers, accounts and mapping labels.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSpendingDetail()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getSpendingDetail$Response(params?: GetSpendingDetail$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SpendingDetailResult>>> {
    return getSpendingDetail(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the detail of spending grouped by suppliers, accounts and mapping labels.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSpendingDetail$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getSpendingDetail(params?: GetSpendingDetail$Params, context?: HttpContext): Observable<Array<SpendingDetailResult>> {
    return this.getSpendingDetail$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SpendingDetailResult>>): Array<SpendingDetailResult> => r.body)
    );
  }

}
