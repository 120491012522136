<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h4>Decarbonisation plan</h4>
    </mat-card-title>
    <span fxFlex></span>
    <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="8px">
      <button mat-stroked-button (click)="openInitiativeBank()">
        <mat-icon fontIcon="icon-archive"></mat-icon>
        Initiatives bank
      </button>
      @if (!noPlannedInitiatives && hasAccessToCreateOwnInitiatives$ | async) {
        <button mat-flat-button color="primary"
          (click)="openEditor()">
          <mat-icon fontIcon="icon-plus"></mat-icon>
          Create new initiative
        </button>
      }
    </div>
  </mat-card-header>
  <mat-card-content>
    <mat-tab-group mat-stretch-tabs="false">
      <mat-tab label="My initiatives">
        <ng-template matTabContent>
          <app-initiatives-planned
            (showSuggestionsEvent)="handleShowSuggestions()"
          (noInitiativesEvent)="handleNoInitiativesEvent($event)"></app-initiatives-planned>
        </ng-template>
      </mat-tab>
      @if (hasAccessToSuggestedInitiatives$ | async) {
        <mat-tab label="Suggested">
          <ng-template matTabContent>
            <app-initiatives-suggested [mp]="mostRecentMp$ | async"></app-initiatives-suggested>
          </ng-template>
        </mat-tab>
      }
      @if ((hasAccessToSuggestedInitiatives$ | async) === false) {
        <mat-tab label="Popular">
          <ng-template matTabContent>
            <app-initiatives-popular></app-initiatives-popular>
          </ng-template>
        </mat-tab>
      }
    </mat-tab-group>
  </mat-card-content>
</mat-card>
