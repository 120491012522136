import {Component, Input, OnInit} from '@angular/core';
import {
  DownloadCategory,
  S3FileObjectDownloadService
} from '../../../../global/services/s3-file-object-download.service';
import {getTypeOfContent, getTypeOfUrl} from '../../../../../utils/function-utils';
import {S3FileObjectPrivateResponse} from '../../../../../api/models/s-3-file-object-private-response';
import {AssetType} from '../../../../../api/models/asset-type';
import {S3FileObjectPublicResponse} from '../../../../../api/models/s-3-file-object-public-response';

@Component({
  selector: 'app-asset-card',
  templateUrl: './asset-card.component.html',
  styleUrls: ['./asset-card.component.scss']
})
export class AssetCardComponent implements OnInit {
  @Input() asset: AssetCardInput;
  @Input() downloadCategory: DownloadCategory;
  protected readonly AssetType = AssetType;
  typeOfAsset: string;

  constructor(private s3FileObjectDownloadService: S3FileObjectDownloadService) {
  }

  ngOnInit(): void {
    if (this.asset.type === AssetType.Link)
      this.typeOfAsset = getTypeOfUrl(this.asset.url);
    if (this.asset.type === AssetType.S3FileObject)
      this.typeOfAsset = getTypeOfContent(this.asset.s3FileObject.contentType);
  }

  download(item: S3FileObjectPrivateResponse) {
    this.s3FileObjectDownloadService.download(item, this.downloadCategory);
  }

  openLink(url: string) {
    window.open(url, '_blank');
  }
}

export interface AssetCardInput {
  title: string;
  type: AssetType;
  headerImage: S3FileObjectPublicResponse;
  url: string;
  s3FileObject: S3FileObjectPrivateResponse
}
