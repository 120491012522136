<mat-dialog-content>
  <div class="flex flex-row items-start justify-center gap-3">
    <mat-icon class="trace-logo trace-rotate3d" svgIcon="trace-leaf-logo"></mat-icon>
    <h4 class="message">{{ title }}</h4>
    <span class="grow"></span>
    @if (indeterminateCounter > 0) {
      <span class="text-xs !text-accent-50">Page {{ indeterminateCounter | number }}</span>
    }
    <mat-icon class="clickable" fontIcon="icon-cancel" (click)="close()"></mat-icon>
  </div>
  <ul>
    @for (error of errors; track error) {
      <li>{{ error }}</li>
    }
  </ul>
  @if (progress || isIndeterminateProgress) {
    <mat-progress-bar [mode]="isIndeterminateProgress ? 'indeterminate' : 'determinate'" [value]="progress"
                      color="accent"></mat-progress-bar>
  }
</mat-dialog-content>
