import {Pipe, PipeTransform} from '@angular/core';
import {CurrentUserResponse} from '../../../api/models/current-user-response';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {

  transform(user: CurrentUserResponse): string {
    const firstNameInitial = user.firstName[0].toUpperCase();
    const lastName = user?.lastName;
    return lastName ? `${firstNameInitial}${lastName[0].toUpperCase()}` : firstNameInitial;
  }

}
