import { Amplify, fetchAuthSession } from '@aws-amplify/core';
import { assertTokenProviderConfig, AuthAction } from '@aws-amplify/core/internals/utils';
import { updateDeviceStatus } from '../utils/clients/CognitoIdentityProvider/index.mjs';
import { assertAuthTokens, assertDeviceMetadata } from '../utils/types.mjs';
import { getRegion } from '../utils/clients/CognitoIdentityProvider/utils.mjs';
import '../utils/refreshAuthTokens.mjs';
import '../tokenProvider/errorHelpers.mjs';
import { tokenOrchestrator } from '../tokenProvider/tokenProvider.mjs';
import { getAuthUserAgentValue } from '../../../utils/getAuthUserAgentValue.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/**
 * Marks device as remembered while authenticated.
 *
 * @throws - {@link UpdateDeviceStatusException} - Cognito service errors thrown when
 * setting device status to remembered using an invalid device key.
 * @throws AuthTokenConfigException - Thrown when the token provider config is invalid.
 */
async function rememberDevice() {
  const authConfig = Amplify.getConfig().Auth?.Cognito;
  assertTokenProviderConfig(authConfig);
  const {
    tokens
  } = await fetchAuthSession();
  assertAuthTokens(tokens);
  const deviceMetadata = await tokenOrchestrator?.getDeviceMetadata();
  assertDeviceMetadata(deviceMetadata);
  await updateDeviceStatus({
    region: getRegion(authConfig.userPoolId),
    userAgentValue: getAuthUserAgentValue(AuthAction.RememberDevice)
  }, {
    AccessToken: tokens.accessToken.toString(),
    DeviceKey: deviceMetadata.deviceKey,
    DeviceRememberedStatus: 'remembered'
  });
}
export { rememberDevice };
