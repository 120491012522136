import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {formatPercentageResponse} from '../../../utils/function-utils';

@Pipe({
  name: 'roundedPercent'
})
export class RoundedPercentPipe implements PipeTransform {

  constructor(
    @Inject(LOCALE_ID) private locale: string) {
  }

  /**
   * Rounded percentage pipes
   *
   * This logic will be moved to backend TRAC-488
   * In the meantime, if the logic needs to be updated, please consider to do the same in BE project. i.e. src/Trace.Application/Extensions/DoubleExtension.cs
   *
   */
  transform(value: number, tolerance: number = 5): unknown {
    return formatPercentageResponse(value, this.locale, tolerance);
  }
}
