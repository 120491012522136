<mat-card fxFlex class="listing-card">
  <app-background-image-section mat-card-image [backgroundUrl]="asset.headerImage.publicUrl" height="200px"
    class="image-container">
  </app-background-image-section>
  <mat-card-content fxFlex>
    <div>
      <div fxLayout="row" fxFlexAlign=" center">
        <h5>{{asset.title}}</h5>
        <span fxFlex></span>
        <span fxFlex="10">
          @if (asset.type === AssetType.S3FileObject) {
            <mat-icon class="medium" (click)="download(asset.s3FileObject)"
            fontIcon="icon-download"></mat-icon>
          }
          @if (asset.type === AssetType.Link) {
            <mat-icon class="medium" (click)="openLink(asset.url)"
            fontIcon="icon-link"></mat-icon>
          }
        </span>
      </div>
      <span>{{typeOfAsset}}</span>
    </div>
  </mat-card-content>
</mat-card>
