/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BaseMappingEngineProcessRequest } from '../../models/base-mapping-engine-process-request';
import { MappingEngineProcessResponse } from '../../models/mapping-engine-process-response';

export interface GetLast$Params {
  
    /**
     * The request containing the collection instance ID and accounting tenant ID.
     */
    body?: BaseMappingEngineProcessRequest
}

export function getLast(http: HttpClient, rootUrl: string, params?: GetLast$Params, context?: HttpContext): Observable<StrictHttpResponse<MappingEngineProcessResponse>> {
  const rb = new RequestBuilder(rootUrl, getLast.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<MappingEngineProcessResponse>;
    })
  );
}

getLast.PATH = '/v1/accounting-mapping-engine-process/get-last';
