import {OAuthProviderType} from '../api/models/o-auth-provider-type';
import {getOAuthProviderLogo} from '../utils/function-utils';

export const masks = {
  thousandSeparator: ',',
  decimalMask: 'separator',
  decimalMask_1: 'separator.1',
  decimalMask_2: 'separator.2',
  phoneMask: '00 0000 000000'
};

export const categoryCodes = {
  Electricity: 'electricity',
  DirectEmissions: 'direct-emissions',
  BusinessTravel: 'business-travel',
  EmployeeCommute: 'employee-commute',
  PurchasedGoodsAndServices: 'purchased-goods-and-services',
  WasteGenerated: 'waste-generated',
  UpstreamTransportAndDistribution: 'upstream-transport-and-distribution',
  Packaging: 'packaging',
  MaterialsAndManufacturing: 'materials-and-manufacturing',
  CapitalGoods: 'capital-goods',
  DownstreamLeasedAssets: 'downstream-leased-assets',
  DownstreamTransportAndDistribution: 'downstream-transport-distribution',
};

export const quillModules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons

    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent

    [{'size': ['huge', 'large', 'small', false]}],  // custom dropdown
    [{'header': [1, 2, 3, 4, 5, 6, false]}],

    [{'color': []}, {'background': []}],          // dropdown with defaults from theme
    [{'align': []}],

    ['clean'],                                         // remove formatting button

    ['link']                         // link and image, video
  ]
};

export const providerLogos = new Map<OAuthProviderType, string>(
  [
    [OAuthProviderType.Xero, getOAuthProviderLogo(OAuthProviderType.Xero)]
  ]
);

export const guideTypeCodes = {
  DataCollection: 'data-collection',
  Decarbonisation: 'decarbonisation',
  Reporting: 'reporting-disclosure'
};

export const emptyGuid = '00000000-0000-0000-0000-000000000000';
export const accountingMappingTeg = /(?<id>[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})-(?<currencyCode>[A-Z]{3})/;

export const traceSupportEmail = 'support@our-trace.com';
export const defaultRoute = '/portal/data';

export const currencySymbolMapping = {
  'AUD': '$',
  'GBP': '£',
  'USD': '$',
  'NZD': '$',
  'EUR': '€',
};

export const csvLogo = '/assets/portal/images/integrations/csv-new.png';
