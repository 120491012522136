import {Component, Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import {WebsocketService} from '../../services/websocket.service';
import {PortalMessageEvent} from '../../../../api/models/portal-message-event';
import {MessageType} from '../../../../api/models/message-type';
import {PortalMessageResult} from '../../../../api/models/portal-message-result';

@Component({
  selector: 'app-real-time-snack-bar',
  templateUrl: './real-time-snack-bar.component.html',
  styleUrls: ['./real-time-snack-bar.component.scss']
})
export class RealTimeSnackBarComponent {
  progress: number | null;
  title: string;
  errors: string[];
  isIndeterminateProgress = false;
  indeterminateCounter = 0;

  constructor(private snackBarRef: MatSnackBarRef<RealTimeSnackBarComponent>) {
  }

  update(title: string, progress: number, errors: string[], isIndeterminateProgress = false, indeterminateCounter = 0) {
    this.title = title;
    this.progress = progress;
    this.errors = errors;
    this.isIndeterminateProgress = isIndeterminateProgress;
    this.indeterminateCounter = indeterminateCounter;
  }

  close() {
    this.snackBarRef.dismiss();
  }
}

@Injectable({
  providedIn: 'root'
})
export class RealTimeSnackBarService {
  private matSnackBarRef: MatSnackBarRef<RealTimeSnackBarComponent>;
  private excludedEvents: PortalMessageEvent[] = [];

  constructor(
    private websocketService: WebsocketService,
    private snackBar: MatSnackBar) {
  }

  excludeEvent(event: PortalMessageEvent) {
    if (!this.excludedEvents.find(e => e == event)) {
      this.excludedEvents.push(event);
    }
  }

  includeEvent(event: PortalMessageEvent) {
    const index = this.excludedEvents.findIndex(e => e == event);
    if (index !== -1) {
      this.excludedEvents.splice(index, 1);
    }
  }

  init() {
    if (!this.websocketService.isConnected()) {
      this.websocketService.connect();
    }

    this.websocketService.messages$
      .subscribe(m => {

        if (!m.title) {
          return;
        }

        if (m.messageType == MessageType.Portal) {
          const portalMessage = m as PortalMessageResult;
          const portalEvent = portalMessage.event;

          if (this.excludedEvents.find(e => e == portalEvent)) {
            return;
          }
        }

        const isProgress = m.properties?.progress != null;
        const isIndeterminateProgress = !!m.properties?.indeteminateProgress;
        const counter = +m.properties?.indeteminateProgressCounter;

        if (this.matSnackBarRef == null || m.isError) {
          this.matSnackBarRef = this.snackBar.openFromComponent(RealTimeSnackBarComponent, {
            duration: m.isError || isProgress || isIndeterminateProgress ? 0 : 3000,
            verticalPosition: 'bottom',
            horizontalPosition: 'right'
          });
        }

        this.updateSnackBarInstance(m.title, isProgress, m.properties.progress, m.isError, m.properties.Errors, isIndeterminateProgress, counter);

        if (isProgress && m.properties.progress == 1) {
          this.matSnackBarRef.dismiss();
          this.matSnackBarRef = null;
        }

        this.matSnackBarRef?.afterDismissed()
          .subscribe(() => {
            this.matSnackBarRef = null;
          });

      });
  }

  private updateSnackBarInstance(title: string, isProgress: boolean, progress: number, isError: boolean, errors: string[], isIndeterminateProgress: boolean, counter: number) {
    this.matSnackBarRef.instance
      .update(title, isProgress ? progress * 100 : null, isError ? errors : null, isIndeterminateProgress, counter);
  }
}
