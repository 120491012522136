/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CollectionInstancesResponse } from '../models/collection-instances-response';
import { CollectionInstanceSummaryResponse } from '../models/collection-instance-summary-response';
import { CollectionLocationSummaryResponse } from '../models/collection-location-summary-response';
import { create } from '../fn/collection-instance/create';
import { Create$Params } from '../fn/collection-instance/create';
import { currents } from '../fn/collection-instance/currents';
import { Currents$Params } from '../fn/collection-instance/currents';
import { currentSummary } from '../fn/collection-instance/current-summary';
import { CurrentSummary$Params } from '../fn/collection-instance/current-summary';
import { currentSummaryV2 } from '../fn/collection-instance/current-summary-v-2';
import { CurrentSummaryV2$Params } from '../fn/collection-instance/current-summary-v-2';
import { downloadAccountingData$Csv } from '../fn/collection-instance/download-accounting-data-csv';
import { DownloadAccountingData$Csv$Params } from '../fn/collection-instance/download-accounting-data-csv';
import { downloadAccountingData$Json } from '../fn/collection-instance/download-accounting-data-json';
import { DownloadAccountingData$Json$Params } from '../fn/collection-instance/download-accounting-data-json';
import { downloadActivityData$Csv } from '../fn/collection-instance/download-activity-data-csv';
import { DownloadActivityData$Csv$Params } from '../fn/collection-instance/download-activity-data-csv';
import { downloadActivityData$Json } from '../fn/collection-instance/download-activity-data-json';
import { DownloadActivityData$Json$Params } from '../fn/collection-instance/download-activity-data-json';
import { getInvalidStepIds } from '../fn/collection-instance/get-invalid-step-ids';
import { GetInvalidStepIds$Params } from '../fn/collection-instance/get-invalid-step-ids';
import { IdResponse } from '../models/id-response';
import { locationSummary } from '../fn/collection-instance/location-summary';
import { LocationSummary$Params } from '../fn/collection-instance/location-summary';
import { markReadyForReview } from '../fn/collection-instance/mark-ready-for-review';
import { MarkReadyForReview$Params } from '../fn/collection-instance/mark-ready-for-review';
import { MarkReadyForReviewResult } from '../models/mark-ready-for-review-result';
import { progressSummary } from '../fn/collection-instance/progress-summary';
import { ProgressSummary$Params } from '../fn/collection-instance/progress-summary';
import { ProgressSummaryResponse } from '../models/progress-summary-response';
import { setDataPeriod } from '../fn/collection-instance/set-data-period';
import { SetDataPeriod$Params } from '../fn/collection-instance/set-data-period';
import { setRequestsEmployeeEngagement } from '../fn/collection-instance/set-requests-employee-engagement';
import { SetRequestsEmployeeEngagement$Params } from '../fn/collection-instance/set-requests-employee-engagement';
import { skipMappingAccount } from '../fn/collection-instance/skip-mapping-account';
import { SkipMappingAccount$Params } from '../fn/collection-instance/skip-mapping-account';
import { summary } from '../fn/collection-instance/summary';
import { Summary$Params } from '../fn/collection-instance/summary';
import { SummaryCollectionInstanceResponse } from '../models/summary-collection-instance-response';

@Injectable({ providedIn: 'root' })
export class CollectionInstanceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1CollectionInstanceCurrentsGet()` */
  static readonly V1CollectionInstanceCurrentsGetPath = '/v1/collection-instance/currents';

  /**
   * Get all collection instances.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `currents()` instead.
   *
   * This method doesn't expect any request body.
   */
  currents$Response(params?: Currents$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CollectionInstancesResponse>>> {
    return currents(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all collection instances.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `currents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  currents(params?: Currents$Params, context?: HttpContext): Observable<Array<CollectionInstancesResponse>> {
    return this.currents$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CollectionInstancesResponse>>): Array<CollectionInstancesResponse> => r.body)
    );
  }

  /** Path part for operation `v1CollectionInstanceSummaryGet()` */
  static readonly V1CollectionInstanceSummaryGetPath = '/v1/collection-instance/summary';

  /**
   * Retrieves a summary of collection instances. (Data Hub information).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `summary()` instead.
   *
   * This method doesn't expect any request body.
   */
  summary$Response(params?: Summary$Params, context?: HttpContext): Observable<StrictHttpResponse<SummaryCollectionInstanceResponse>> {
    return summary(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a summary of collection instances. (Data Hub information).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `summary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  summary(params?: Summary$Params, context?: HttpContext): Observable<SummaryCollectionInstanceResponse> {
    return this.summary$Response(params, context).pipe(
      map((r: StrictHttpResponse<SummaryCollectionInstanceResponse>): SummaryCollectionInstanceResponse => r.body)
    );
  }

  /** Path part for operation `v1CollectionInstanceProgressSummaryPost()` */
  static readonly V1CollectionInstanceProgressSummaryPostPath = '/v1/collection-instance/progress-summary';

  /**
   * Retrieves the progress summary of collection instances.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `progressSummary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  progressSummary$Response(params?: ProgressSummary$Params, context?: HttpContext): Observable<StrictHttpResponse<ProgressSummaryResponse>> {
    return progressSummary(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the progress summary of collection instances.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `progressSummary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  progressSummary(params?: ProgressSummary$Params, context?: HttpContext): Observable<ProgressSummaryResponse> {
    return this.progressSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProgressSummaryResponse>): ProgressSummaryResponse => r.body)
    );
  }

  /** Path part for operation `v1CollectionInstanceCurrentSummaryPost()` */
  static readonly V1CollectionInstanceCurrentSummaryPostPath = '/v1/collection-instance/current-summary';

  /**
   * Get the base steps.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `currentSummary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  currentSummary$Response(params?: CurrentSummary$Params, context?: HttpContext): Observable<StrictHttpResponse<CollectionInstanceSummaryResponse>> {
    return currentSummary(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the base steps.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `currentSummary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  currentSummary(params?: CurrentSummary$Params, context?: HttpContext): Observable<CollectionInstanceSummaryResponse> {
    return this.currentSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<CollectionInstanceSummaryResponse>): CollectionInstanceSummaryResponse => r.body)
    );
  }

  /** Path part for operation `v1CollectionInstanceCurrentSummaryV2Post()` */
  static readonly V1CollectionInstanceCurrentSummaryV2PostPath = '/v1/collection-instance/current-summary-v2';

  /**
   * Get the base steps for DC Form V2.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `currentSummaryV2()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  currentSummaryV2$Response(params?: CurrentSummaryV2$Params, context?: HttpContext): Observable<StrictHttpResponse<CollectionInstanceSummaryResponse>> {
    return currentSummaryV2(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the base steps for DC Form V2.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `currentSummaryV2$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  currentSummaryV2(params?: CurrentSummaryV2$Params, context?: HttpContext): Observable<CollectionInstanceSummaryResponse> {
    return this.currentSummaryV2$Response(params, context).pipe(
      map((r: StrictHttpResponse<CollectionInstanceSummaryResponse>): CollectionInstanceSummaryResponse => r.body)
    );
  }

  /** Path part for operation `v1CollectionInstanceLocationSummaryPost()` */
  static readonly V1CollectionInstanceLocationSummaryPostPath = '/v1/collection-instance/location-summary';

  /**
   * Get location count summary.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `locationSummary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  locationSummary$Response(params?: LocationSummary$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CollectionLocationSummaryResponse>>> {
    return locationSummary(this.http, this.rootUrl, params, context);
  }

  /**
   * Get location count summary.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `locationSummary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  locationSummary(params?: LocationSummary$Params, context?: HttpContext): Observable<Array<CollectionLocationSummaryResponse>> {
    return this.locationSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CollectionLocationSummaryResponse>>): Array<CollectionLocationSummaryResponse> => r.body)
    );
  }

  /** Path part for operation `v1CollectionInstanceMarkReadyForReviewPost()` */
  static readonly V1CollectionInstanceMarkReadyForReviewPostPath = '/v1/collection-instance/mark-ready-for-review';

  /**
   * Update collection status to ready for review.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markReadyForReview()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  markReadyForReview$Response(params?: MarkReadyForReview$Params, context?: HttpContext): Observable<StrictHttpResponse<MarkReadyForReviewResult>> {
    return markReadyForReview(this.http, this.rootUrl, params, context);
  }

  /**
   * Update collection status to ready for review.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markReadyForReview$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  markReadyForReview(params?: MarkReadyForReview$Params, context?: HttpContext): Observable<MarkReadyForReviewResult> {
    return this.markReadyForReview$Response(params, context).pipe(
      map((r: StrictHttpResponse<MarkReadyForReviewResult>): MarkReadyForReviewResult => r.body)
    );
  }

  /** Path part for operation `v1CollectionInstanceGetInvalidStepIdsPost()` */
  static readonly V1CollectionInstanceGetInvalidStepIdsPostPath = '/v1/collection-instance/get-invalid-step-ids';

  /**
   * Retrieves a list of invalid step IDs for a given ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInvalidStepIds()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getInvalidStepIds$Response(params?: GetInvalidStepIds$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getInvalidStepIds(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a list of invalid step IDs for a given ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInvalidStepIds$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getInvalidStepIds(params?: GetInvalidStepIds$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getInvalidStepIds$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `v1CollectionInstanceSetDataPeriodPost()` */
  static readonly V1CollectionInstanceSetDataPeriodPostPath = '/v1/collection-instance/set-data-period';

  /**
   * Set Date Period to a Collection Instance.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setDataPeriod()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setDataPeriod$Response(params?: SetDataPeriod$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setDataPeriod(this.http, this.rootUrl, params, context);
  }

  /**
   * Set Date Period to a Collection Instance.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setDataPeriod$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setDataPeriod(params?: SetDataPeriod$Params, context?: HttpContext): Observable<void> {
    return this.setDataPeriod$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1CollectionInstanceSetRequestsEmployeeEngagementPost()` */
  static readonly V1CollectionInstanceSetRequestsEmployeeEngagementPostPath = '/v1/collection-instance/set-requests-employee-engagement';

  /**
   * Sets the employee engagement requests for a collection instance.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setRequestsEmployeeEngagement()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setRequestsEmployeeEngagement$Response(params?: SetRequestsEmployeeEngagement$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setRequestsEmployeeEngagement(this.http, this.rootUrl, params, context);
  }

  /**
   * Sets the employee engagement requests for a collection instance.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setRequestsEmployeeEngagement$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setRequestsEmployeeEngagement(params?: SetRequestsEmployeeEngagement$Params, context?: HttpContext): Observable<void> {
    return this.setRequestsEmployeeEngagement$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1CollectionInstanceSkipMappingAccountPost()` */
  static readonly V1CollectionInstanceSkipMappingAccountPostPath = '/v1/collection-instance/skip-mapping-account';

  /**
   * Skips mapping account for a collection instance.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `skipMappingAccount()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  skipMappingAccount$Response(params?: SkipMappingAccount$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return skipMappingAccount(this.http, this.rootUrl, params, context);
  }

  /**
   * Skips mapping account for a collection instance.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `skipMappingAccount$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  skipMappingAccount(params?: SkipMappingAccount$Params, context?: HttpContext): Observable<void> {
    return this.skipMappingAccount$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1CollectionInstanceCreatePost()` */
  static readonly V1CollectionInstanceCreatePostPath = '/v1/collection-instance/create';

  /**
   * Creates a new collection instance.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method doesn't expect any request body.
   */
  create$Response(params?: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<IdResponse>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a new collection instance.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  create(params?: Create$Params, context?: HttpContext): Observable<IdResponse> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<IdResponse>): IdResponse => r.body)
    );
  }

  /** Path part for operation `v1CollectionInstanceDownloadActivityDataPost()` */
  static readonly V1CollectionInstanceDownloadActivityDataPostPath = '/v1/collection-instance/download-activity-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadActivityData$Csv()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  downloadActivityData$Csv$Response(params?: DownloadActivityData$Csv$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return downloadActivityData$Csv(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadActivityData$Csv$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  downloadActivityData$Csv(params?: DownloadActivityData$Csv$Params, context?: HttpContext): Observable<Blob> {
    return this.downloadActivityData$Csv$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadActivityData$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  downloadActivityData$Json$Response(params?: DownloadActivityData$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return downloadActivityData$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadActivityData$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  downloadActivityData$Json(params?: DownloadActivityData$Json$Params, context?: HttpContext): Observable<Blob> {
    return this.downloadActivityData$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `v1CollectionInstanceDownloadAccountingDataPost()` */
  static readonly V1CollectionInstanceDownloadAccountingDataPostPath = '/v1/collection-instance/download-accounting-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadAccountingData$Csv()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  downloadAccountingData$Csv$Response(params?: DownloadAccountingData$Csv$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return downloadAccountingData$Csv(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadAccountingData$Csv$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  downloadAccountingData$Csv(params?: DownloadAccountingData$Csv$Params, context?: HttpContext): Observable<Blob> {
    return this.downloadAccountingData$Csv$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadAccountingData$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  downloadAccountingData$Json$Response(params?: DownloadAccountingData$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return downloadAccountingData$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadAccountingData$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  downloadAccountingData$Json(params?: DownloadAccountingData$Json$Params, context?: HttpContext): Observable<Blob> {
    return this.downloadAccountingData$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}
