import {Component, OnInit} from '@angular/core';
import initAnimation from './animated-big-flower.component-function';

@Component({
  selector: 'app-animated-big-flower',
  templateUrl: './animated-big-flower.component.html',
  styleUrl: './animated-big-flower.component.scss'
})
export class AnimatedBigFlowerComponent implements OnInit {
  ngOnInit(): void {
    initAnimation();
  }

}
