import {Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';

@Component({
  selector: 'app-background-image-section',
  templateUrl: './background-image-section.component.html',
  styleUrls: ['./background-image-section.component.scss']
})
export class BackgroundImageSectionComponent implements OnChanges {
  @Input() backgroundUrl: string;
  @Input() opacity = 0;
  @Input() height: string = null;
  @Input() borderRadius: string = null;
  @Input() borderTopRightRadius: string = null;
  @Input() borderBottomRightRadius: string = null;
  @Input() minWidth: string = null;
  @Input() minHeight: string = null;

  @ViewChild('imageSection', {static: true}) imageSection: ElementRef;

  ngOnChanges(changes: SimpleChanges): void {
    const opacity = changes['opacity'];

    if (opacity) {
      this.imageSection.nativeElement.style.setProperty('--opacity', this.opacity);
    }
  }
}
