import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-portal-dialog-header',
  templateUrl: './portal-dialog-header.component.html',
  styleUrls: ['./portal-dialog-header.component.scss']
})
export class PortalDialogHeaderComponent {
  @Input() title = '';
  @Input() closeResult = false;
}
