/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CategoryExclusionResponse } from '../models/category-exclusion-response';
import { exclusionSummary } from '../fn/emission-factor/exclusion-summary';
import { ExclusionSummary$Params } from '../fn/emission-factor/exclusion-summary';

@Injectable({ providedIn: 'root' })
export class EmissionFactorService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1EmissionFactorExclusionSummaryPost()` */
  static readonly V1EmissionFactorExclusionSummaryPostPath = '/v1/emission-factor/exclusion-summary';

  /**
   * Exclusions Summaries.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exclusionSummary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  exclusionSummary$Response(params?: ExclusionSummary$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CategoryExclusionResponse>>> {
    return exclusionSummary(this.http, this.rootUrl, params, context);
  }

  /**
   * Exclusions Summaries.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exclusionSummary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  exclusionSummary(params?: ExclusionSummary$Params, context?: HttpContext): Observable<Array<CategoryExclusionResponse>> {
    return this.exclusionSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CategoryExclusionResponse>>): Array<CategoryExclusionResponse> => r.body)
    );
  }

}
