/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addAccountingInfo } from '../fn/companies/add-accounting-info';
import { AddAccountingInfo$Params } from '../fn/companies/add-accounting-info';
import { CompanyResponse } from '../models/company-response';
import { current } from '../fn/companies/current';
import { Current$Params } from '../fn/companies/current';
import { getMeasurementProfiles } from '../fn/companies/get-measurement-profiles';
import { GetMeasurementProfiles$Params } from '../fn/companies/get-measurement-profiles';
import { homeStatSummary } from '../fn/companies/home-stat-summary';
import { HomeStatSummary$Params } from '../fn/companies/home-stat-summary';
import { HomeStatSummaryResponse } from '../models/home-stat-summary-response';
import { isCompanyExist } from '../fn/companies/is-company-exist';
import { IsCompanyExist$Params } from '../fn/companies/is-company-exist';
import { MinimalMeasurementProfileResponse } from '../models/minimal-measurement-profile-response';
import { update } from '../fn/companies/update';
import { Update$Params } from '../fn/companies/update';

@Injectable({ providedIn: 'root' })
export class CompaniesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1CompaniesCurrentGet()` */
  static readonly V1CompaniesCurrentGetPath = '/v1/companies/current';

  /**
   * Current company in the context.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `current()` instead.
   *
   * This method doesn't expect any request body.
   */
  current$Response(params?: Current$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyResponse>> {
    return current(this.http, this.rootUrl, params, context);
  }

  /**
   * Current company in the context.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `current$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  current(params?: Current$Params, context?: HttpContext): Observable<CompanyResponse> {
    return this.current$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyResponse>): CompanyResponse => r.body)
    );
  }

  /** Path part for operation `v1CompaniesUpdatePut()` */
  static readonly V1CompaniesUpdatePutPath = '/v1/companies/update';

  /**
   * Update an Company Details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  update$Response(params?: Update$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return update(this.http, this.rootUrl, params, context);
  }

  /**
   * Update an Company Details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  update(params?: Update$Params, context?: HttpContext): Observable<void> {
    return this.update$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1CompaniesIsCompanyExistPost()` */
  static readonly V1CompaniesIsCompanyExistPostPath = '/v1/companies/is-company-exist';

  /**
   * Is Company already available by company name.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isCompanyExist()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  isCompanyExist$Response(params?: IsCompanyExist$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return isCompanyExist(this.http, this.rootUrl, params, context);
  }

  /**
   * Is Company already available by company name.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isCompanyExist$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  isCompanyExist(params?: IsCompanyExist$Params, context?: HttpContext): Observable<boolean> {
    return this.isCompanyExist$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `v1CompaniesHomeStatSummaryPost()` */
  static readonly V1CompaniesHomeStatSummaryPostPath = '/v1/companies/home-stat-summary';

  /**
   * Home Summary Resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `homeStatSummary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  homeStatSummary$Response(params?: HomeStatSummary$Params, context?: HttpContext): Observable<StrictHttpResponse<HomeStatSummaryResponse>> {
    return homeStatSummary(this.http, this.rootUrl, params, context);
  }

  /**
   * Home Summary Resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `homeStatSummary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  homeStatSummary(params?: HomeStatSummary$Params, context?: HttpContext): Observable<HomeStatSummaryResponse> {
    return this.homeStatSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<HomeStatSummaryResponse>): HomeStatSummaryResponse => r.body)
    );
  }

  /** Path part for operation `v1CompaniesAddAccountingInfoPost()` */
  static readonly V1CompaniesAddAccountingInfoPostPath = '/v1/companies/add-accounting-info';

  /**
   * Add extra accounting integration info.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addAccountingInfo()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addAccountingInfo$Response(params?: AddAccountingInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return addAccountingInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * Add extra accounting integration info.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addAccountingInfo$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addAccountingInfo(params?: AddAccountingInfo$Params, context?: HttpContext): Observable<void> {
    return this.addAccountingInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1CompaniesGetMeasurementProfilesGet()` */
  static readonly V1CompaniesGetMeasurementProfilesGetPath = '/v1/companies/get-measurement-profiles';

  /**
   * Get Measurement Profiles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMeasurementProfiles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMeasurementProfiles$Response(params?: GetMeasurementProfiles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MinimalMeasurementProfileResponse>>> {
    return getMeasurementProfiles(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Measurement Profiles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMeasurementProfiles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMeasurementProfiles(params?: GetMeasurementProfiles$Params, context?: HttpContext): Observable<Array<MinimalMeasurementProfileResponse>> {
    return this.getMeasurementProfiles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MinimalMeasurementProfileResponse>>): Array<MinimalMeasurementProfileResponse> => r.body)
    );
  }

}
