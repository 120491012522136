/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ArticleItemResponse } from '../models/article-item-response';
import { ArticleItemResponsePagedResult } from '../models/article-item-response-paged-result';
import { ArticleMinimalResponse } from '../models/article-minimal-response';
import { ArticleResponse } from '../models/article-response';
import { getById } from '../fn/article/get-by-id';
import { GetById$Params } from '../fn/article/get-by-id';
import { getByIds } from '../fn/article/get-by-ids';
import { GetByIds$Params } from '../fn/article/get-by-ids';
import { getFeaturedRandom } from '../fn/article/get-featured-random';
import { GetFeaturedRandom$Params } from '../fn/article/get-featured-random';
import { getLatestCreated } from '../fn/article/get-latest-created';
import { GetLatestCreated$Params } from '../fn/article/get-latest-created';
import { getRandomProjects } from '../fn/article/get-random-projects';
import { GetRandomProjects$Params } from '../fn/article/get-random-projects';
import { getSummaryById } from '../fn/article/get-summary-by-id';
import { GetSummaryById$Params } from '../fn/article/get-summary-by-id';
import { search } from '../fn/article/search';
import { Search$Params } from '../fn/article/search';

@Injectable({ providedIn: 'root' })
export class ArticleService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1ArticleGetLatestCreatedPost()` */
  static readonly V1ArticleGetLatestCreatedPostPath = '/v1/article/get-latest-created';

  /**
   * Get articles randomly.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLatestCreated()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getLatestCreated$Response(params?: GetLatestCreated$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ArticleItemResponse>>> {
    return getLatestCreated(this.http, this.rootUrl, params, context);
  }

  /**
   * Get articles randomly.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLatestCreated$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getLatestCreated(params?: GetLatestCreated$Params, context?: HttpContext): Observable<Array<ArticleItemResponse>> {
    return this.getLatestCreated$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ArticleItemResponse>>): Array<ArticleItemResponse> => r.body)
    );
  }

  /** Path part for operation `v1ArticleGetFeaturedRandomGet()` */
  static readonly V1ArticleGetFeaturedRandomGetPath = '/v1/article/get-featured-random';

  /**
   * Get one featured article randomly.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFeaturedRandom()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFeaturedRandom$Response(params?: GetFeaturedRandom$Params, context?: HttpContext): Observable<StrictHttpResponse<ArticleItemResponse>> {
    return getFeaturedRandom(this.http, this.rootUrl, params, context);
  }

  /**
   * Get one featured article randomly.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFeaturedRandom$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFeaturedRandom(params?: GetFeaturedRandom$Params, context?: HttpContext): Observable<ArticleItemResponse> {
    return this.getFeaturedRandom$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArticleItemResponse>): ArticleItemResponse => r.body)
    );
  }

  /** Path part for operation `v1ArticleGetByIdPost()` */
  static readonly V1ArticleGetByIdPostPath = '/v1/article/get-by-id';

  /**
   * Get article full information.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getById$Response(params?: GetById$Params, context?: HttpContext): Observable<StrictHttpResponse<ArticleResponse>> {
    return getById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get article full information.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getById(params?: GetById$Params, context?: HttpContext): Observable<ArticleResponse> {
    return this.getById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArticleResponse>): ArticleResponse => r.body)
    );
  }

  /** Path part for operation `v1ArticleGetByIdsPost()` */
  static readonly V1ArticleGetByIdsPostPath = '/v1/article/get-by-ids';

  /**
   * Get article list based on ids.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getByIds()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getByIds$Response(params?: GetByIds$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ArticleItemResponse>>> {
    return getByIds(this.http, this.rootUrl, params, context);
  }

  /**
   * Get article list based on ids.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getByIds$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getByIds(params?: GetByIds$Params, context?: HttpContext): Observable<Array<ArticleItemResponse>> {
    return this.getByIds$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ArticleItemResponse>>): Array<ArticleItemResponse> => r.body)
    );
  }

  /** Path part for operation `v1ArticleGetSummaryByIdPost()` */
  static readonly V1ArticleGetSummaryByIdPostPath = '/v1/article/get-summary-by-id';

  /**
   * Get article summary details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSummaryById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getSummaryById$Response(params?: GetSummaryById$Params, context?: HttpContext): Observable<StrictHttpResponse<ArticleMinimalResponse>> {
    return getSummaryById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get article summary details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSummaryById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getSummaryById(params?: GetSummaryById$Params, context?: HttpContext): Observable<ArticleMinimalResponse> {
    return this.getSummaryById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArticleMinimalResponse>): ArticleMinimalResponse => r.body)
    );
  }

  /** Path part for operation `v1ArticleSearchPost()` */
  static readonly V1ArticleSearchPostPath = '/v1/article/search';

  /**
   * Search articles by type.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Response(params?: Search$Params, context?: HttpContext): Observable<StrictHttpResponse<ArticleItemResponsePagedResult>> {
    return search(this.http, this.rootUrl, params, context);
  }

  /**
   * Search articles by type.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search(params?: Search$Params, context?: HttpContext): Observable<ArticleItemResponsePagedResult> {
    return this.search$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArticleItemResponsePagedResult>): ArticleItemResponsePagedResult => r.body)
    );
  }

  /** Path part for operation `v1ArticleGetRandomProjectsPost()` */
  static readonly V1ArticleGetRandomProjectsPostPath = '/v1/article/get-random-projects';

  /**
   * Get random projects.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRandomProjects()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getRandomProjects$Response(params?: GetRandomProjects$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ArticleItemResponse>>> {
    return getRandomProjects(this.http, this.rootUrl, params, context);
  }

  /**
   * Get random projects.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRandomProjects$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getRandomProjects(params?: GetRandomProjects$Params, context?: HttpContext): Observable<Array<ArticleItemResponse>> {
    return this.getRandomProjects$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ArticleItemResponse>>): Array<ArticleItemResponse> => r.body)
    );
  }

}
