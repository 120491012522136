<mat-form-field fxFlex>
  <mat-label>{{title}}</mat-label>
  <mat-select [formControl]="formEnumControl" [required]="required">
    @if (allowNone) {
      <mat-option>None</mat-option>
    }
    @for (value of allowValues; track value) {
      <mat-option [value]="value">
        {{value | enumFormatter}}
      </mat-option>
    }
  </mat-select>
  @if (formEnumControl.invalid) {
    <mat-error>Required</mat-error>
  }
</mat-form-field>
