import {Component, Input} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'app-enum-selector',
  templateUrl: './enum-selector.component.html',
  styleUrls: ['./enum-selector.component.scss']
})
export class EnumSelectorComponent<T> {
  @Input() title: string = '';
  @Input() formEnumControl: UntypedFormControl;
  @Input() allowValues: T[] = [];
  @Input() required = true;
  @Input() allowNone = true;
}
