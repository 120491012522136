import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {DateRangeResponse} from '../../../api/models/date-range-response';
import {formatDateRangeResponse} from '../../../utils/function-utils';

@Pipe({
  name: 'dateRange'
})
export class DateRangePipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private locale: string) {
  }

  transform(value: DateRangeResponse): unknown {
    return formatDateRangeResponse(value, this.locale);
  }

}
