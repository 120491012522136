/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DownloadPreSignedUrlResponse } from '../models/download-pre-signed-url-response';
import { getDownloadUrlById } from '../fn/s-3-file-objects/get-download-url-by-id';
import { GetDownloadUrlById$Params } from '../fn/s-3-file-objects/get-download-url-by-id';
import { getDownloadUrlByKey } from '../fn/s-3-file-objects/get-download-url-by-key';
import { GetDownloadUrlByKey$Params } from '../fn/s-3-file-objects/get-download-url-by-key';
import { getUploadPreSignedUrl } from '../fn/s-3-file-objects/get-upload-pre-signed-url';
import { GetUploadPreSignedUrl$Params } from '../fn/s-3-file-objects/get-upload-pre-signed-url';
import { UploadPreSignedUrlResponse } from '../models/upload-pre-signed-url-response';

@Injectable({ providedIn: 'root' })
export class S3FileObjectsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1S3FileObjectsGetDownloadUrlByIdPost()` */
  static readonly V1S3FileObjectsGetDownloadUrlByIdPostPath = '/v1/s3file-objects/get-download-url-by-id';

  /**
   * Get Download url.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDownloadUrlById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDownloadUrlById$Response(params?: GetDownloadUrlById$Params, context?: HttpContext): Observable<StrictHttpResponse<DownloadPreSignedUrlResponse>> {
    return getDownloadUrlById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Download url.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDownloadUrlById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDownloadUrlById(params?: GetDownloadUrlById$Params, context?: HttpContext): Observable<DownloadPreSignedUrlResponse> {
    return this.getDownloadUrlById$Response(params, context).pipe(
      map((r: StrictHttpResponse<DownloadPreSignedUrlResponse>): DownloadPreSignedUrlResponse => r.body)
    );
  }

  /** Path part for operation `v1S3FileObjectsGetDownloadUrlByKeyPost()` */
  static readonly V1S3FileObjectsGetDownloadUrlByKeyPostPath = '/v1/s3file-objects/get-download-url-by-key';

  /**
   * Get Download Url by key.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDownloadUrlByKey()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDownloadUrlByKey$Response(params?: GetDownloadUrlByKey$Params, context?: HttpContext): Observable<StrictHttpResponse<DownloadPreSignedUrlResponse>> {
    return getDownloadUrlByKey(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Download Url by key.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDownloadUrlByKey$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDownloadUrlByKey(params?: GetDownloadUrlByKey$Params, context?: HttpContext): Observable<DownloadPreSignedUrlResponse> {
    return this.getDownloadUrlByKey$Response(params, context).pipe(
      map((r: StrictHttpResponse<DownloadPreSignedUrlResponse>): DownloadPreSignedUrlResponse => r.body)
    );
  }

  /** Path part for operation `v1S3FileObjectsGetUploadPreSignedUrlPost()` */
  static readonly V1S3FileObjectsGetUploadPreSignedUrlPostPath = '/v1/s3file-objects/get-upload-pre-signed-url';

  /**
   * Get a pre-signed Url to upload a file to S3.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUploadPreSignedUrl()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getUploadPreSignedUrl$Response(params?: GetUploadPreSignedUrl$Params, context?: HttpContext): Observable<StrictHttpResponse<UploadPreSignedUrlResponse>> {
    return getUploadPreSignedUrl(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a pre-signed Url to upload a file to S3.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUploadPreSignedUrl$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getUploadPreSignedUrl(params?: GetUploadPreSignedUrl$Params, context?: HttpContext): Observable<UploadPreSignedUrlResponse> {
    return this.getUploadPreSignedUrl$Response(params, context).pipe(
      map((r: StrictHttpResponse<UploadPreSignedUrlResponse>): UploadPreSignedUrlResponse => r.body)
    );
  }

}
