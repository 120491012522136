<svg id="e8NZwtkV3qQ1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
     viewBox="0 0 500 300" shape-rendering="geometricPrecision" text-rendering="geometricPrecision">
  <g transform="translate(160.966509-39.695747)">
    <g transform="matrix(.628293 0 0 0.628293-100.118179 89.981825)">
      <path
        d="M106.81,333.08c26.89-20.79,91.45-41.73,170.6-9c33.35,14.26,7.15,18.68-8.32,16.27-19.56-3-6.67,19.82-35,24.51s-90,.11-111.49-6.83c-15.79-5.1-31.31-12.95-15.79-24.95Z"
        transform="translate(112.15914-6.247903)" fill="#804d2d"/>
    </g>
    <g id="e8NZwtkV3qQ5" transform="translate(.000001 0)">
      <path d="M44.635,127.059h50.033586" fill="none" stroke="#d74a51" stroke-width="1.62812"/>
    </g>
    <path
      d="M95.3609,287.186c0,.45.3645.814.8141.814s.814-.364.814-.814h-1.6281Zm-.9785-159.648751L95.3609,287.186h1.6281l-.765664-159.383821-1.840936-.26493Z"
      transform="matrix(1.811802 0 0 1-77.059939 0)" fill="#d74a51"/>
    <g id="e8NZwtkV3qQ8">
      <g>
        <path
          d="M96.2045,188.948c-.3899,5.124-7.9024,5.124-8.2923,0-.1558-2.048-1.783-3.675-3.8306-3.831-5.1235-.389-5.1235-7.902,0-8.292c2.0476-.156,3.6748-1.783,3.8306-3.83.3899-5.124,7.9024-5.124,8.2923,0c.1558,2.047,1.783,3.674,3.8305,3.83c5.124.39,5.124,7.903,0,8.292-2.0475.156-3.6747,1.783-3.8305,3.831Z"
          transform="translate(2.323913 0)" fill="#ec6973"/>
        <path
          d="M94.3824,178.647c1.2839,1.283,1.2839,3.365,0,4.649s-3.3656,1.284-4.6495,0-1.2839-3.366,0-4.649c1.2839-1.284,3.3656-1.284,4.6495,0Z"
          transform="translate(2.323913 0)" fill="#f4b542" stroke="#dc8433" stroke-width="1.64384"/>
      </g>
    </g>
    <g id="e8NZwtkV3qQ12" transform="translate(25.451699 66.383333)">
      <path
        d="M5.7625,64.4751c-5.123505-.3899-5.123506-7.9024,0-8.2923c2.0476-.1558,3.67484-1.783,3.83065-3.8306.38985-5.1236,7.90245-5.1236,8.29225,0c.1558,2.0476,1.783,3.6748,3.8306,3.8306c5.1236.3899,5.1236,7.9024,0,8.2923-2.0476.1558-3.6748,1.783-3.8306,3.8306-.3898,5.1235-7.9024,5.1235-8.29225,0-.15581-2.0476-1.78304-3.6748-3.83065-3.8306Z"
        fill="#ec6973"/>
      <path
        d="M16.064,62.653c-1.2839,1.2839-3.3655,1.2839-4.6494,0-1.284-1.2839-1.284-3.3656,0-4.6495c1.2839-1.2839,3.3655-1.2839,4.6494,0s1.2839,3.3656,0,4.6495Z"
        fill="#f4b542" stroke="#dc8433" stroke-width="1.64384"/>
    </g>
    <g>
      <g id="e8NZwtkV3qQ16" transform="translate(0 0.000002)">
        <path d="M51.3728,101.823l43.416,24.893982" transform="translate(0 0.149018)" fill="none" stroke="#d74a51"
              stroke-width="1.62812"/>
        <g transform="translate(0 0.000001)">
          <path
            d="M39.0416,100.951c-4.2387-2.9062-.4925-9.4181,4.135-7.1874c1.8493.8915,4.0687.2969,5.2246-1.3999c2.8921-4.2456,9.3923-.4792,7.1748,4.1573-.8862,1.853-.2897,4.079,1.4043,5.241c4.2387,2.907.4925,9.418-4.135,7.188-1.8493-.892-4.0687-.297-5.2245,1.4-2.8922,4.245-9.3924.479-7.1749-4.158.8862-1.853.2897-4.079-1.4043-5.241Z"
            fill="#ec6973"/>
          <path
            d="M48.8636,104.537c-1.7512.469-3.5523-.575-4.023-2.331-.4706-1.757.5674-3.5614,2.3185-4.0306s3.5523.5744,4.0229,2.3306c.4707,1.757-.5673,3.561-2.3184,4.031Z"
            fill="#f4b542" stroke="#dc8433" stroke-width="1.64384"/>
        </g>
      </g>
      <g id="e8NZwtkV3qQ21">
        <path d="M94.668586,126.866001L69.7801,83.3496" fill="none" stroke="#d74a51" stroke-width="1.62812"/>
        <path
          d="M59.947,77.548c-2.2175-4.6365,4.2827-8.4029,7.1749-4.1573c1.1558,1.6967,3.3752,2.2914,5.2246,1.3999c4.6274-2.2307,8.3736,4.2812,4.1349,7.1878-1.694,1.1616-2.2905,3.3879-1.4043,5.2409c2.2175,4.6365-4.2827,8.4029-7.1748,4.1573-1.1559-1.6968-3.3752-2.2915-5.2246-1.3999-4.6275,2.2307-8.3736-4.2812-4.135-7.1878c1.694-1.1616,2.2906-3.3879,1.4043-5.2409Z"
          fill="#ec6973"/>
        <path
          d="M66.6604,85.5639c-1.7511-.4692-2.7891-2.2736-2.3184-4.0302.4707-1.7565,2.2718-2.8002,4.0229-2.331s2.7892,2.2736,2.3185,4.0302-2.2718,2.8002-4.023,2.331Z"
          fill="#f4b542" stroke="#dc8433" stroke-width="1.64384"/>
      </g>
      <g id="e8NZwtkV3qQ25" transform="translate(0 0.000003)">
        <path d="M94.92505,126.866004L94.9253,76.5877" fill="none" stroke="#d74a51" stroke-width="1.62812"/>
        <path
          d="M89.7034,67.7891c.3899-5.1235,7.9024-5.1235,8.2923,0c.1558,2.0476,1.783,3.6749,3.8303,3.8307c5.124.3898,5.124,7.9024,0,8.2922-2.0473.1558-3.6745,1.7831-3.8303,3.8307-.3899,5.1235-7.9024,5.1235-8.2923,0-.1558-2.0476-1.783-3.6749-3.8306-3.8307-5.1235-.3898-5.1235-7.9024,0-8.2922c2.0476-.1558,3.6748-1.783,3.8306-3.8307Z"
          fill="#ec6973"/>
        <path
          d="M91.5255,78.0907c-1.2839-1.284-1.2839-3.3656,0-4.6495c1.284-1.2839,3.3656-1.2839,4.6495,0s1.2839,3.3655,0,4.6495-3.3655,1.2839-4.6495,0Z"
          fill="#f4b542" stroke="#dc8433" stroke-width="1.64384"/>
      </g>
      <g id="e8NZwtkV3qQ29" transform="translate(0 0.000001)">
        <path d="M120.07,83.3495L94.92505,126.866006" fill="none" stroke="#d74a51" stroke-width="1.62812"/>
        <path
          d="M120.304,74.2702c2.892-4.2456,9.393-.4792,7.175,4.1573-.886,1.853-.29,4.0793,1.404,5.2409c4.239,2.9066.493,9.4185-4.134,7.1878-1.85-.8915-4.069-.2968-5.225,1.3999-2.892,4.2456-9.392.4792-7.175-4.1573.886-1.853.29-4.0793-1.404-5.2409-4.239-2.9066-.493-9.4185,4.135-7.1878c1.849.8915,4.068.2969,5.224-1.3999Z"
          fill="#ec6973"/>
        <path
          d="M116.744,84.113c-.471-1.7565.567-3.5609,2.318-4.0301c1.752-.4692,3.553.5744,4.023,2.331.471,1.7566-.567,3.5609-2.318,4.0302-1.751.4692-3.552-.5745-4.023-2.3311Z"
          fill="#f4b542" stroke="#dc8433" stroke-width="1.64384"/>
      </g>
      <g id="e8NZwtkV3qQ33" transform="translate(0 0.000002)">
        <path d="M94.9255,126.725l43.5525-24.902" fill="none" stroke="#d74a51" stroke-width="1.62812"/>
        <path
          d="M143.569,95.2201c4.628-2.2307,8.374,4.2812,4.135,7.1879-1.694,1.161-2.29,3.388-1.404,5.241c2.218,4.636-4.283,8.403-7.175,4.157-1.156-1.697-3.375-2.291-5.224-1.4-4.628,2.231-8.374-4.281-4.135-7.188c1.694-1.161,2.29-3.3875,1.404-5.2405-2.218-4.6365,4.283-8.4029,7.175-4.1573c1.156,1.6968,3.375,2.2914,5.224,1.3999Z"
          fill="#ec6973"/>
        <path
          d="M135.565,101.964c.47-1.756,2.271-2.8001,4.023-2.3308c1.751.4688,2.789,2.2738,2.318,4.0298-.471,1.757-2.272,2.801-4.023,2.331-1.751-.469-2.789-2.273-2.318-4.03Z"
          fill="#f4b542" stroke="#dc8433" stroke-width="1.64384"/>
      </g>
      <g id="e8NZwtkV3qQ37" transform="translate(0 0.000014)">
        <path d="M145.216,127.059h-49.870679" fill="none" stroke="#d74a51" stroke-width="1.62812"/>
        <path
          d="M153.299,125.044c5.123.39,5.123,7.903,0,8.293-2.048.155-3.675,1.783-3.831,3.83-.39,5.124-7.902,5.124-8.292,0-.156-2.047-1.783-3.675-3.831-3.83-5.123-.39-5.123-7.903,0-8.293c2.048-.156,3.675-1.783,3.831-3.83.39-5.124,7.902-5.124,8.292,0c.156,2.047,1.783,3.674,3.831,3.83Z"
          transform="translate(.339837-.679675)" fill="#ec6973"/>
        <path
          d="M142.997,126.866c1.284-1.284,3.366-1.284,4.65,0s1.284,3.366,0,4.65-3.366,1.284-4.65,0-1.284-3.366,0-4.65Z"
          transform="translate(.339837-.679675)" fill="#f4b542" stroke="#dc8433" stroke-width="1.64384"/>
      </g>
      <g id="e8NZwtkV3qQ41">
        <path d="M94.668586,126.575982L138.478,152.295" transform="translate(0 0.035739)" fill="none" stroke="#d74a51"
              stroke-width="1.62812"/>
        <path
          d="M146.866,155.786c4.239,2.906.493,9.418-4.135,7.187-1.849-.891-4.068-.296-5.224,1.4-2.892,4.246-9.393.48-7.175-4.157.886-1.853.29-4.079-1.404-5.241-4.239-2.906-.493-9.418,4.135-7.188c1.849.892,4.068.297,5.224-1.399c2.892-4.246,9.393-.48,7.175,4.157-.886,1.853-.29,4.079,1.404,5.241Z"
          transform="translate(.362375-1.487159)" fill="#ec6973"/>
        <path
          d="M137.044,152.201c1.751-.47,3.553.574,4.023,2.331.471,1.756-.567,3.561-2.318,4.03s-3.552-.575-4.023-2.331c-.471-1.757.567-3.561,2.318-4.03Z"
          transform="translate(.362375-1.487159)" fill="#f4b542" stroke="#dc8433" stroke-width="1.64384"/>
      </g>
      <g id="e8NZwtkV3qQ45" transform="translate(0 0.000001)">
        <path d="M120.07,170.769L94.668586,126.866004" fill="none" stroke="#d74a51" stroke-width="1.62812"/>
        <path
          d="M125.961,179.189c2.217,4.637-4.283,8.403-7.175,4.157-1.156-1.696-3.375-2.291-5.225-1.399-4.627,2.23-8.373-4.282-4.135-7.188c1.694-1.162,2.291-3.388,1.405-5.241-2.218-4.637,4.282-8.403,7.175-4.157c1.155,1.696,3.375,2.291,5.224,1.399c4.628-2.23,8.374,4.282,4.135,7.188-1.694,1.162-2.29,3.388-1.404,5.241Z"
          transform="translate(3.571184-.021682)" fill="#ec6973"/>
        <path
          d="M119.247,171.173c1.752.469,2.79,2.274,2.319,4.03-.471,1.757-2.272,2.801-4.023,2.331-1.751-.469-2.789-2.273-2.319-4.03.471-1.756,2.272-2.8,4.023-2.331Z"
          transform="translate(3.684463-.021682)" fill="#f4b542" stroke="#dc8433" stroke-width="1.64384"/>
      </g>
      <g id="e8NZwtkV3qQ49" transform="translate(0 0.000001)">
        <path d="M69.7801,170.769l24.6023-43.23109" fill="none" stroke="#d74a51" stroke-width="1.62812"/>
        <path
          d="M65.6036,182.467c-2.8922,4.246-9.3924.479-7.1749-4.157.8863-1.853.2897-4.08-1.4042-5.241-4.2387-2.907-.4926-9.419,4.1349-7.188c1.8494.891,4.0688.297,5.2246-1.4c2.8922-4.246,9.3924-.479,7.1748,4.157-.8862,1.853-.2897,4.08,1.4043,5.241c4.2387,2.907.4926,9.419-4.1349,7.188-1.8494-.892-4.0688-.297-5.2246,1.4Z"
          fill="#ec6973"/>
        <path
          d="M69.1639,172.624c.4706,1.757-.5674,3.561-2.3185,4.03s-3.5523-.574-4.0229-2.331c-.4707-1.756.5673-3.561,2.3184-4.03c1.7512-.469,3.5523.574,4.023,2.331Z"
          fill="#f4b542" stroke="#dc8433" stroke-width="1.64384"/>
      </g>
      <g id="e8NZwtkV3qQ53">
        <path d="M94.7696,127.059L51.3725,152.295" transform="translate(-.143874 0)" fill="none" stroke="#d74a51"
              stroke-width="1.62812"/>
        <path
          d="M57.6885,128.846c-.9451-5.051,6.3097-7.002,8.0078-2.153.6786,1.938,2.6698,3.087,4.6873,2.706c5.0483-.954,6.9862,6.304,2.139,8.012-1.9371.682-3.0888,2.677-2.7111,4.695.9452,5.052-6.3096,7.003-8.0077,2.154-.6787-1.938-2.6698-3.088-4.6874-2.706-5.0483.953-6.9861-6.305-2.139-8.012c1.9372-.682,3.0889-2.677,2.7111-4.696Z"
          transform="translate(-9.468066 13.148606)" fill="#ec6973"/>
        <path
          d="M62.1054,138.326c-1.571-.907-2.108-2.918-1.1993-4.492s2.9189-2.115,4.4899-1.208c1.5711.907,2.108,2.919,1.1994,4.492-.9087,1.574-2.9189,2.115-4.49,1.208Z"
          transform="translate(-9.468066 13.148604)" fill="#f4b542" stroke="#dc8433" stroke-width="1.64384"/>
      </g>
    </g>
    <g transform="translate(0 0.000001)">
      <g id="e8NZwtkV3qQ58">
        <path
          d="M90.5224,118.748c.3899-5.123,7.9024-5.123,8.2923,0c.1558,2.048,1.7833,3.675,3.8303,3.831c5.124.39,5.124,7.902,0,8.292-2.047.156-3.6745,1.783-3.8303,3.831-.3899,5.123-7.9024,5.123-8.2923,0-.1558-2.048-1.783-3.675-3.8306-3.831-5.1235-.39-5.1235-7.902,0-8.292c2.0476-.156,3.6748-1.783,3.8306-3.831Z"
          fill="#ec6973"/>
        <path
          d="M92.3445,129.05c-1.2839-1.284-1.2839-3.366,0-4.65s3.3656-1.284,4.6495,0s1.2839,3.366,0,4.65c-1.2839,1.283-3.3656,1.283-4.6495,0Z"
          fill="#f4b542" stroke="#dc8433" stroke-width="1.64384"/>
      </g>
      <g id="e8NZwtkV3qQ61" transform="translate(.000001 0.000001)">
        <path
          d="M63.4213,107.476c-.9452-5.051,6.3097-7.002,8.0077-2.153.6787,1.938,2.6699,3.087,4.6874,2.706c5.0483-.954,6.9862,6.305,2.139,8.012-1.9372.682-3.0888,2.677-2.7111,4.695.9451,5.052-6.3097,7.003-8.0078,2.154-.6786-1.938-2.6698-3.088-4.6873-2.706-5.0483.954-6.9862-6.305-2.139-8.012c1.9371-.682,3.0888-2.677,2.7111-4.696Z"
          fill="#ec6973"/>
        <path
          d="M67.8382,116.956c-1.5711-.907-2.1081-2.918-1.1994-4.492s2.9189-2.115,4.49-1.208c1.571.907,2.108,2.919,1.1993,4.493-.9087,1.573-2.9189,2.114-4.4899,1.207Z"
          fill="#f4b542" stroke="#dc8433" stroke-width="1.64384"/>
      </g>
      <g id="e8NZwtkV3qQ64" transform="translate(0 0.000001)">
        <path
          d="M78.9816,91.8598c-.9451-5.0514,6.3097-7.0023,8.0078-2.1534.6786,1.9378,2.6698,3.0874,4.6873,2.7062c5.0483-.9539,6.9862,6.3044,2.139,8.0114-1.9371.682-3.0888,2.677-2.7111,4.696.9452,5.051-6.3096,7.002-8.0077,2.153-.6787-1.937-2.6698-3.087-4.6874-2.706-5.0483.954-6.9861-6.3043-2.139-8.0114c1.9372-.6823,3.0889-2.6771,2.7111-4.6958Z"
          fill="#ec6973"/>
        <path
          d="M83.3985,101.339c-1.571-.907-2.108-2.9178-1.1993-4.4916s2.9189-2.1145,4.4899-1.2075c1.5711.9071,2.1081,2.9183,1.1994,4.4921-.9087,1.574-2.9189,2.115-4.49,1.207Z"
          fill="#f4b542" stroke="#dc8433" stroke-width="1.64384"/>
      </g>
      <g id="e8NZwtkV3qQ67">
        <path
          d="M101.913,95.9693c-.945-5.0513,6.309-7.0022,8.007-2.1534.679,1.9379,2.67,3.0875,4.688,2.7063c5.048-.9539,6.986,6.3048,2.139,8.0118-1.937.682-3.089,2.677-2.711,4.696.945,5.051-6.31,7.002-8.008,2.153-.679-1.938-2.67-3.087-4.687-2.706-5.0487.954-6.9866-6.305-2.1394-8.012c1.9374-.6822,3.0884-2.6769,2.7114-4.6957Z"
          fill="#ec6973"/>
        <path
          d="M106.33,105.449c-1.571-.907-2.108-2.918-1.2-4.492.909-1.574,2.919-2.1146,4.49-1.2075c1.571.9075,2.108,2.9185,1.2,4.4925-.909,1.573-2.919,2.114-4.49,1.207Z"
          fill="#f4b542" stroke="#dc8433" stroke-width="1.64384"/>
      </g>
      <g id="e8NZwtkV3qQ70" transform="translate(0 0.000001)">
        <path
          d="M117.473,114.874c-.945-5.052,6.31-7.003,8.008-2.154.678,1.938,2.67,3.088,4.687,2.706c5.048-.953,6.986,6.305,2.139,8.012-1.937.682-3.089,2.677-2.711,4.696.945,5.051-6.31,7.002-8.008,2.153-.678-1.938-2.67-3.087-4.687-2.706-5.048.954-6.986-6.304-2.139-8.012c1.937-.682,3.089-2.677,2.711-4.695Z"
          fill="#ec6973"/>
        <path
          d="M121.89,124.353c-1.571-.907-2.108-2.918-1.199-4.492.908-1.574,2.918-2.114,4.49-1.207c1.571.907,2.108,2.918,1.199,4.492s-2.919,2.114-4.49,1.207Z"
          fill="#f4b542" stroke="#dc8433" stroke-width="1.64384"/>
      </g>
      <g id="e8NZwtkV3qQ73">
        <path
          d="M112.559,136.243c-.945-5.051,6.31-7.002,8.008-2.153.679,1.938,2.67,3.087,4.687,2.706c5.049-.954,6.987,6.305,2.139,8.012-1.937.682-3.088,2.677-2.711,4.696.945,5.051-6.309,7.002-8.008,2.153-.678-1.938-2.669-3.087-4.687-2.706-5.048.954-6.986-6.305-2.139-8.012c1.937-.682,3.089-2.677,2.711-4.696Z"
          fill="#ec6973"/>
        <path
          d="M116.976,145.723c-1.571-.907-2.108-2.918-1.199-4.492.908-1.574,2.919-2.114,4.49-1.207s2.108,2.918,1.199,4.492-2.919,2.114-4.49,1.207Z"
          fill="#f4b542" stroke="#dc8433" stroke-width="1.64384"/>
      </g>
      <g id="e8NZwtkV3qQ76" transform="translate(0 0.000001)">
        <path
          d="M95.3609,151.86c-.9451-5.051,6.3101-7.002,8.0081-2.154.678,1.938,2.67,3.088,4.687,2.707c5.048-.954,6.986,6.304,2.139,8.011-1.937.683-3.089,2.677-2.711,4.696.945,5.051-6.31,7.002-8.0078,2.154-.6787-1.938-2.6698-3.088-4.6874-2.707-5.0483.954-6.9861-6.304-2.139-8.011c1.9372-.683,3.0889-2.677,2.7111-4.696Z"
          fill="#ec6973"/>
        <path
          d="M99.7778,161.34c-1.571-.907-2.108-2.919-1.1993-4.493.9087-1.573,2.9185-2.114,4.4895-1.207s2.108,2.918,1.2,4.492c-.909,1.574-2.919,2.115-4.4902,1.208Z"
          fill="#f4b542" stroke="#dc8433" stroke-width="1.64384"/>
      </g>
      <g id="e8NZwtkV3qQ79">
        <path
          d="M74.0678,145.285c-.9451-5.052,6.3097-7.003,8.0078-2.154.6786,1.938,2.6698,3.088,4.6873,2.706c5.0483-.953,6.9862,6.305,2.139,8.012-1.9371.682-3.0888,2.677-2.7111,4.696.9452,5.051-6.3096,7.002-8.0077,2.153-.6787-1.938-2.6698-3.087-4.6874-2.706-5.0483.954-6.9861-6.304-2.139-8.012c1.9372-.682,3.0889-2.677,2.7111-4.695Z"
          fill="#ec6973"/>
        <path
          d="M78.4847,154.764c-1.571-.907-2.108-2.918-1.1993-4.492s2.9189-2.114,4.4899-1.207c1.5711.907,2.108,2.918,1.1994,4.492s-2.9189,2.114-4.49,1.207Z"
          fill="#f4b542" stroke="#dc8433" stroke-width="1.64384"/>
      </g>
    </g>
    <g id="e8NZwtkV3qQ82">
      <path id="e8NZwtkV3qQ83"
            d="M92.058487,243.602c-2.407292-21.351259-20.360724-31.469103-38.057394-38.466103s-49.317663-9.190897-49.317663-9.190897v46.028c.408174,21.572791,25.162344,42.157537,45.798995,43.740402L96.4076,288c0,0-1.941821-23.046741-4.349113-44.398Z"
            fill="#74aa63"/>
      <path d="M94.7696,286.356C65.2869,269.794,6.32136,230.17,6.32136,197.589" fill="none" stroke="#638a4e"
            stroke-width="1.64384" stroke-linecap="round"/>
    </g>
    <g id="e8NZwtkV3qQ85" transform="translate(.000002 0)">
      <path id="e8NZwtkV3qQ86"
            d="M146.364,265.397c0,12.483-10.119,22.603-22.602,22.603h-22.441v-22.603c0-12.483,10.12-22.602,22.603-22.602h22.44v22.602Z"
            fill="#74aa63"/>
      <path d="M145.558,243.602c-14.478,8.133-43.435,27.591-43.435,43.591" fill="none" stroke="#638a4e"
            stroke-width="0.807241" stroke-linecap="round"/>
    </g>
    <g id="e8NZwtkV3qQ88" transform="translate(0 0.000001)">
      <path
        d="M154.554,209.096c0,7.263-5.888,13.151-13.151,13.151h-13.056v-13.151c0-7.263,5.888-13.151,13.151-13.151h13.056v13.151Z"
        fill="#74aa63"/>
      <path d="M154.085,196.415c-8.424,4.732-25.271,16.053-25.271,25.362" fill="none" stroke="#638a4e"
            stroke-width="0.469667" stroke-linecap="round"/>
    </g>
  </g>
</svg>
