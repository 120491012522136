import { Component } from '@angular/core';

@Component({
  selector: 'app-reduce-welcome-dialog',
  templateUrl: './reduce-welcome-dialog.component.html',
  styleUrls: ['./reduce-welcome-dialog.component.scss']
})
export class ReduceWelcomeDialogComponent {

}
