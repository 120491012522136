/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getLast } from '../fn/accounting-mapping-engine-process/get-last';
import { GetLast$Params } from '../fn/accounting-mapping-engine-process/get-last';
import { MappingEngineProcessResponse } from '../models/mapping-engine-process-response';
import { runProcess } from '../fn/accounting-mapping-engine-process/run-process';
import { RunProcess$Params } from '../fn/accounting-mapping-engine-process/run-process';

@Injectable({ providedIn: 'root' })
export class AccountingMappingEngineProcessService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1AccountingMappingEngineProcessGetLastPost()` */
  static readonly V1AccountingMappingEngineProcessGetLastPostPath = '/v1/accounting-mapping-engine-process/get-last';

  /**
   * Retrieves the last mapping engine process status for the given collection instance and accounting tenant.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLast()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getLast$Response(params?: GetLast$Params, context?: HttpContext): Observable<StrictHttpResponse<MappingEngineProcessResponse>> {
    return getLast(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the last mapping engine process status for the given collection instance and accounting tenant.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLast$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getLast(params?: GetLast$Params, context?: HttpContext): Observable<MappingEngineProcessResponse> {
    return this.getLast$Response(params, context).pipe(
      map((r: StrictHttpResponse<MappingEngineProcessResponse>): MappingEngineProcessResponse => r.body)
    );
  }

  /** Path part for operation `v1AccountingMappingEngineProcessRunProcessPost()` */
  static readonly V1AccountingMappingEngineProcessRunProcessPostPath = '/v1/accounting-mapping-engine-process/run-process';

  /**
   * Send the SQS message to trigger the mapping engine process.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `runProcess()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  runProcess$Response(params?: RunProcess$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return runProcess(this.http, this.rootUrl, params, context);
  }

  /**
   * Send the SQS message to trigger the mapping engine process.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `runProcess$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  runProcess(params?: RunProcess$Params, context?: HttpContext): Observable<void> {
    return this.runProcess$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
