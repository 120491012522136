/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { autocomplete } from '../fn/geolocation/autocomplete';
import { Autocomplete$Params } from '../fn/geolocation/autocomplete';
import { getPlace } from '../fn/geolocation/get-place';
import { GetPlace$Params } from '../fn/geolocation/get-place';
import { PlaceDetailResult } from '../models/place-detail-result';
import { StringAutocompleteResponse } from '../models/string-autocomplete-response';

@Injectable({ providedIn: 'root' })
export class GeolocationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1GeolocationAutocompletePost()` */
  static readonly V1GeolocationAutocompletePostPath = '/v1/geolocation/autocomplete/autocomplete';

  /**
   * Autocomplete Service.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `autocomplete()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  autocomplete$Response(params?: Autocomplete$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<StringAutocompleteResponse>>> {
    return autocomplete(this.http, this.rootUrl, params, context);
  }

  /**
   * Autocomplete Service.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `autocomplete$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  autocomplete(params?: Autocomplete$Params, context?: HttpContext): Observable<Array<StringAutocompleteResponse>> {
    return this.autocomplete$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StringAutocompleteResponse>>): Array<StringAutocompleteResponse> => r.body)
    );
  }

  /** Path part for operation `v1GeolocationGetPlacePost()` */
  static readonly V1GeolocationGetPlacePostPath = '/v1/geolocation/get-place/getplace';

  /**
   * Get Place Detail.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlace()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getPlace$Response(params?: GetPlace$Params, context?: HttpContext): Observable<StrictHttpResponse<PlaceDetailResult>> {
    return getPlace(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Place Detail.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlace$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getPlace(params?: GetPlace$Params, context?: HttpContext): Observable<PlaceDetailResult> {
    return this.getPlace$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlaceDetailResult>): PlaceDetailResult => r.body)
    );
  }

}
