import {Component} from '@angular/core';

@Component({
  selector: 'app-new-version-dialog',
  templateUrl: './new-version-dialog.component.html',
  styleUrls: ['./new-version-dialog.component.scss']
})
export class NewVersionDialogComponent {

  update() {
    window.location.reload();
  }
}
