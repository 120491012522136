import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DynamicScriptLoadingService {

  loadDynamicScript(urlScript: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const scriptElement = window.document.createElement('script');
      scriptElement.src = urlScript;

      scriptElement.onload = () => {
        resolve(null);
      };

      scriptElement.onerror = () => {
        reject();
      };

      window.document.body.appendChild(scriptElement);
    });
  }
}
