<app-loading-container [isLoading]="isLoading">
  <div class="mat-elevation-z1 table-container">
    <table mat-table [dataSource]="list" matSort [matSortActive]="sortActive" [matSortDirection]="sortDirection"
           matSortDisableClear
           aria-describedby="Data table" [trackBy]="trackByFn">

      @for (column of displayedColumnObjects; track column.id; let last = $last) {
        <ng-container [matColumnDef]="column.id">
          <th scope="col" mat-header-cell *matHeaderCellDef [mat-sort-header]="column.sort"
              [disabled]="!column.sort" [style.width]="column.headerWidth">
            @if (column.id !== 'select') {
              <div>
                {{ column.title }}
              </div>
            }
            @if (column.id === 'select') {
              <div>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
              </div>
            }
            @if (last && enableDisplayIdColumn) {
              <div>
                <span fxFlex></span>
                <button mat-icon-button>
                  <mat-icon [matMenuTriggerFor]="menu" fontIcon="icon-more-vertical"></mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  @if (!displayIdColumn) {
                    <button (click)="displayColumnId(true)" mat-menu-item>
                      <mat-icon fontIcon="icon-info"></mat-icon>
                      <span>Display Id Column</span>
                    </button>
                  }
                  @if (displayIdColumn) {
                    <button (click)="displayColumnId(false)" mat-menu-item>
                      <mat-icon fontIcon="icon-info"></mat-icon>
                      <span>Hide Id Column</span>
                    </button>
                  }
                </mat-menu>
              </div>
            }
          </th>
          <td mat-cell *matCellDef="let element">
            @if (column.id === 'select') {
              <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? onChangeSelectionCheckBox(element) : null"
                            [checked]="selection.isSelected(element)">
              </mat-checkbox>
            }
            @if (column.id === 'id') {
              <button (click)="copyToClipboard(element[column.id])" mat-icon-button class="mat-caption"
                      matTooltip="Copy to clipboard">
                <mat-icon fontIcon="icon-copy"></mat-icon>
              </button>
              <span>{{ element[column.id] }}</span>
            }
            @if (!column.useTemplate && column.id !== 'select') {
              <span>{{ element[column.id] }}</span>
            } @else {
              <ng-template [ngTemplateOutletContext]="{
                      element: element[column.id],
                      column: column,
                      fullElement: element,
                      isSelected: selection.isSelected(element),
                      tableHasSelections: selection.selected.length > 0,
                    }" [ngTemplateOutlet]="templateRow">
              </ng-template>
            }
          </td>
        </ng-container>
      }

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true;"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" (click)="clickRow(row)"
          [class.clickable]="enableRowClick"
          [class.visited]="enableMarkRowAsVisited && row.id === lastVisitedId"></tr>
    </table>
  </div>
  @if (activatePaging) {
    <mat-paginator
      [length]="totalLength"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      [showFirstLastButtons]="showFirstLastButtons">
    </mat-paginator>
  }
</app-loading-container>
