import {Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {CurrentUserResponse} from '../../../api/models/current-user-response';
import {UsersService} from '../../../api/services/users.service';
import {from, Observable, switchMap} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {fetchMFAPreference, FetchMFAPreferenceOutput} from 'aws-amplify/auth';

interface UserState {
  currentUser: CurrentUserResponseExtended | null;
}

@Injectable({providedIn: 'root'})
export class UserStore extends ComponentStore<UserState> {

  constructor(private usersService: UsersService) {
    super({
      currentUser: null
    });
  }

  initCurrentUser(): Observable<CurrentUserResponseExtended> {
    return this.usersService.current()
      .pipe(
        switchMap(user => {
          return this.getMFAPreferences(user);
        }),
        tap(currentUser => this.setCurrentUser(currentUser))
      );
  }

  // Updaters
  readonly setCurrentUser = this.updater<CurrentUserResponseExtended>((state, currentUser) => ({
    ...state,
    currentUser
  }));

  // Effects
  readonly getCurrentUser = this.effect(trigger$ => {
    return trigger$.pipe(
      switchMap(() => this.usersService.current()
        .pipe(switchMap(user => {
          return this.getMFAPreferences(user);
        }))
      ),
      tap((currentUser: CurrentUserResponseExtended) => this.setCurrentUser(currentUser))
    );
  });

  // Selectors
  readonly currentUser$ = this.select(state => state.currentUser);

  private getMFAPreferences(currentUser: CurrentUserResponse) {
    return from(from(fetchMFAPreference()))
      .pipe(
        map(mfaPreferences => {
          return {
            ...currentUser,
            MFAPreference: mfaPreferences
          } as CurrentUserResponseExtended;
        })
      );
  }
}

export interface CurrentUserResponseExtended extends CurrentUserResponse {
  MFAPreference: FetchMFAPreferenceOutput;
}
