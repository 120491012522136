import {Directive, ElementRef, Injectable, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CompanyStore} from '../../portal/store/company-store';
import {FeatureName} from '../../../api/models/feature-name';

@Directive({
  selector: '[appIsEnabledFeature]'
})
/**
 * @deprecated Use the service FeatureVisibilityService directly instead
 */
export class IsEnabledFeatureDirective implements OnInit {

  @Input() appIsEnabledFeature: Feature;

  constructor(private element: ElementRef,
              private featureVisibilityService: FeatureVisibilityService) {
  }

  ngOnInit(): void {
    this.featureVisibilityService.isEligibleFeature$(this.appIsEnabledFeature).subscribe(
      isEnabled => {
        if (this.element?.nativeElement && !isEnabled) {
          this.element.nativeElement.style.display = 'none';
        }
      });
  }
}

@Injectable({
  providedIn: 'root',
})
export class FeatureVisibilityService {
  companyPlan: string;

  constructor(private companyStore: CompanyStore) {
  }

  /**
   * @deprecated User the other functions instead
   * @param feature
   */
  public isEligibleFeature$(feature: Feature): Observable<boolean> {
    return this.companyStore.currentCompany$.pipe(
      map(c => {
        const proPlans = ['Starter', 'Plus', 'Pro', 'Enterprise'];
        this.companyPlan = c.companyPaymentInfo.planId;
        switch (feature) {
          case Feature.MultiPnL:
          case Feature.Scope:
          case Feature.YearOnYear:
          case Feature.ReductionOpportunities:
            return proPlans.includes(c.companyPaymentInfo.planId);
          default:
            return false;
        }
      })
    );
  }

  /**
   * this is a temporary way to manage features because we don't have one at the moment
   * One idea is in the future we use ChargeBee for this
   * @param feature
   */
  public hasAccessToFeature(feature: FeatureName): Observable<boolean> {
    return this.companyStore.currentCompany$.pipe(
      map(c => {
        return c.features.includes(feature);
      })
    );
  }
}

/**
 * @deprecated Use FeatureName enum instead
 */
export enum Feature {
  MultiPnL,
  Scope,
  YearOnYear,
  ReductionOpportunities
}
