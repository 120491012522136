/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CurrentGuidesRequest } from '../../models/current-guides-request';
import { GuideItemResponse } from '../../models/guide-item-response';

export interface CurrentsV2$Params {
      body?: CurrentGuidesRequest
}

export function currentsV2(http: HttpClient, rootUrl: string, params?: CurrentsV2$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GuideItemResponse>>> {
  const rb = new RequestBuilder(rootUrl, currentsV2.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<GuideItemResponse>>;
    })
  );
}

currentsV2.PATH = '/v1/guide/currents-v2';
