<div #imageSection
     [style.background-image]="'url(' + backgroundUrl + ')'" class="background-section"
     [style.height]=" height !== null ? height : 'unset'"
     [style.border-radius]="borderRadius !== null ? borderRadius : 'unset'"
     [style.border-top-right-radius]="borderTopRightRadius !== null ? borderTopRightRadius : 'unset'"
     [style.border-bottom-right-radius]="borderBottomRightRadius !== null ? borderBottomRightRadius : 'unset'"
     [style.min-width]="minWidth !== null ? minWidth : 'unset'"
     [style.min-height]="minHeight !== null ? minHeight : 'unset'">
  <ng-content></ng-content>
</div>
