import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TypedFormBuilderService} from '../../../../../global/services/typed-form-builder.service';
import {quillModules} from '../../../../../../system-constants/constants';
import {InitiativeService} from '../../../../../../api/services/initiative.service';
import {CategoryItemResponse} from '../../../../../../api/models/category-item-response';
import {EnumSelectorComponent} from '../../../../../global/components/shared/enum-selector/enum-selector.component';
import {PnLTagResponse} from '../../../../../../api/models/pn-l-tag-response';
import {EffortRatingType} from '../../../../../../api/models/effort-rating-type';
import {UiUtilService} from '../../../../../global/services/ui-util.service';
import {ImpactRatingType} from '../../../../../../api/models/impact-rating-type';
import {
  EnumOption,
  EnumSelectorButtonComponent
} from '../../../../../global/components/shared/enum-selector-button/enum-selector-button.component';
import {NotificationService} from '../../../../../global/services/notification.service';
import {CategoryService} from '../../../../../../api/services/category.service';
import {CreateInitiativeInput, InitiativeStatus, UpdateInitiativeInput} from '../../../../../../api/models';
import {EffortIconMapping, ImpactIconMapping} from '../initiatives/initiatives.component';
import {GATraceService, TrackingAction, TrackingCategory} from '../../../../../global/services/ga-trace.service';
import {CompanyStore} from '../../../../store/company-store';
import {InitiativeEventHandlingService} from '../../service/initiative-event-handling.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-initiative-editor',
  templateUrl: './initiative-editor.component.html',
  styleUrls: ['./initiative-editor.component.scss']
})
export class InitiativeEditorComponent implements OnInit, OnDestroy {
  initiativeEditorForm = this.typedFormBuilderService.initiativeEditorForm();
  categories: CategoryItemResponse[] = [];
  @ViewChild(EnumSelectorComponent, {static: true}) statusControl: EnumSelectorComponent<InitiativeStatus>;
  @ViewChild('effort', {static: true}) costEffortControl: EnumSelectorButtonComponent<EffortRatingType>;
  @ViewChild('impact', {static: true}) impactControl: EnumSelectorButtonComponent<ImpactRatingType>;
  entities: PnLTagResponse[] = [];
  companyName: string;
  originalInitiativeStatus: InitiativeStatus;
  effortOptions: EnumOption<EffortRatingType>[] = [
    {
      displayName: 'Low',
      icon: EffortIconMapping[EffortRatingType.Low],
      value: EffortRatingType.Low,
      isSelected: false
    },
    {
      displayName: 'Medium',
      icon: EffortIconMapping[EffortRatingType.Medium],
      value: EffortRatingType.Medium,
      isSelected: false
    },
    {
      displayName: 'High',
      icon: EffortIconMapping[EffortRatingType.High],
      value: EffortRatingType.High,
      isSelected: false
    }
  ];
  impactOptions: EnumOption<ImpactRatingType>[] = [
    {
      displayName: 'Low',
      icon: ImpactIconMapping[ImpactRatingType.Low],
      value: ImpactRatingType.Low,
      isSelected: false
    },
    {
      displayName: 'Medium',
      icon: ImpactIconMapping[ImpactRatingType.Medium],
      value: ImpactRatingType.Medium,
      isSelected: false
    },
    {
      displayName: 'High',
      icon: ImpactIconMapping[ImpactRatingType.High],
      value: ImpactRatingType.High,
      isSelected: false
    }
  ];
  initiativeCreatedEvent$ = this
    .initiativeEventHandlingService
    .initiativeCreatedEvent$
    .pipe(takeUntilDestroyed());
  initiativeUpdatedEvent$ = this
    .initiativeEventHandlingService
    .initiativeUpdatedEvent$
    .pipe(takeUntilDestroyed());

  subscriptions = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public initiativeId: string,
              private dialogRef: MatDialogRef<InitiativeEditorComponent>,
              private typedFormBuilderService: TypedFormBuilderService,
              private initiativeService: InitiativeService,
              private categoryService: CategoryService,
              private companyStore: CompanyStore,
              private uiUtilService: UiUtilService,
              private notificationService: NotificationService,
              private gaTraceService: GATraceService,
              private initiativeEventHandlingService: InitiativeEventHandlingService) {
  }

  ngOnInit() {
    this.statusControl.allowValues = Object.values(InitiativeStatus);

    const storeSub = this.companyStore.currentCompany$
      .subscribe(c => {
        this.companyName = c.name;
        this.entities = c.pnLTags;
        if (this.entities.length === 1) {
          this.initiativeEditorForm.controls.entity.setValue(this.entities[0].id);
        }
      });

    const categoryServiceSub = this.categoryService
      .getParentCategories()
      .subscribe(res => {
        this.categories = res;
      });

    if (this.isEditing) {

      const initiativeServiceSub = this.initiativeService.getById({
        body: {
          id: this.initiativeId
        }
      }).subscribe(res => {
        this.initiativeEditorForm.setValue({
          name: res.name,
          category: res.category.id,
          entity: res.pnLTagId,
          costEffort: res.effort,
          impact: res.impact,
          status: res.status,
          content: res.content
        });
        this.costEffortControl.select(res.effort);
        this.impactControl.select(res.impact);
        this.initiativeEditorForm.markAsUntouched();
        this.originalInitiativeStatus = res.status;
      });

      this.subscriptions.add(initiativeServiceSub);
    }

    const createEventSub = this.initiativeCreatedEvent$
      .subscribe(() => {
        this.notificationService.showSuccess('Initiative created');
        this.gaTraceService.event(TrackingAction.addTheirOwnInitiativeToCarbonisationPlan, TrackingCategory.reduce, this.companyName);
        this.gaTraceService.event(TrackingAction.setInitiativeStatus, TrackingCategory.reduce, this.initiativeEditorForm.value.status);
        this.dialogRef.close(true);
      });

    const updateEventSub = this.initiativeUpdatedEvent$
      .subscribe(() => {
        this.notificationService.showSuccess('Initiative updated');
        if (this.originalInitiativeStatus !== this.initiativeEditorForm.value.status) {
          this.gaTraceService.event(TrackingAction.setInitiativeStatus, TrackingCategory.reduce, `${this.companyName}-${this.initiativeEditorForm.value.status}`);
        }
        this.dialogRef.close(true);
      });

    this.subscriptions.add(storeSub);
    this.subscriptions.add(categoryServiceSub);
    this.subscriptions.add(createEventSub);
    this.subscriptions.add(updateEventSub);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get isEditing() {
    return !!this.initiativeId;
  }

  get quillModules() {
    return quillModules;
  }

  close() {
    if (!this.initiativeEditorForm.touched) {
      this.dialogRef.close();
    } else {
      this.uiUtilService.confirmationAction(
        `You have unsaved information. Are you sure you want to close it?`,
        (result) => {
          if (result) {
            this.dialogRef.close();
          }
        });
    }
  }

  create() {
    const createInitiativeInput: CreateInitiativeInput = {
      name: this.initiativeEditorForm.value.name,
      content: this.initiativeEditorForm.value.content,
      effortRating: this.initiativeEditorForm.value.costEffort,
      impactRating: this.initiativeEditorForm.value.impact,
      status: this.initiativeEditorForm.value?.status,
      pnLTagId: this.initiativeEditorForm.value.entity,
      parentCategoryId: this.initiativeEditorForm.value.category
    };
    this.initiativeEventHandlingService.createInitiative(createInitiativeInput);
  }

  update() {
    const updateIntiativeInput: UpdateInitiativeInput = {
      id: this.initiativeId,
      name: this.initiativeEditorForm.value.name,
      content: this.initiativeEditorForm.value.content,
      effortRating: this.initiativeEditorForm.value.costEffort,
      impactRating: this.initiativeEditorForm.value.impact,
      status: this.initiativeEditorForm.value.status,
      pnLTagId: this.initiativeEditorForm.value.entity,
      parentCategoryId: this.initiativeEditorForm.value.category
    };
    this.initiativeEventHandlingService.updateInitiative(updateIntiativeInput);
  }

  protected readonly EffortRatingType = EffortRatingType;
  protected readonly ImpactRatingType = ImpactRatingType;
}
