/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CategoryBreakdownItemResponsePagedResult } from '../models/category-breakdown-item-response-paged-result';
import { categoryByEfBreakdown } from '../fn/measurement-profiles/category-by-ef-breakdown';
import { CategoryByEfBreakdown$Params } from '../fn/measurement-profiles/category-by-ef-breakdown';
import { categoryBySupplierBreakdown } from '../fn/measurement-profiles/category-by-supplier-breakdown';
import { CategoryBySupplierBreakdown$Params } from '../fn/measurement-profiles/category-by-supplier-breakdown';
import { categorySummary } from '../fn/measurement-profiles/category-summary';
import { CategorySummary$Params } from '../fn/measurement-profiles/category-summary';
import { CategorySummaryResponse } from '../models/category-summary-response';
import { electricityBreakdown } from '../fn/measurement-profiles/electricity-breakdown';
import { ElectricityBreakdown$Params } from '../fn/measurement-profiles/electricity-breakdown';
import { impactSummary } from '../fn/measurement-profiles/impact-summary';
import { ImpactSummary$Params } from '../fn/measurement-profiles/impact-summary';
import { ImpactSummaryResponse } from '../models/impact-summary-response';
import { pnLTagCategorySummary } from '../fn/measurement-profiles/pn-l-tag-category-summary';
import { PnLTagCategorySummary$Params } from '../fn/measurement-profiles/pn-l-tag-category-summary';
import { PnLTagCategorySummaryResponse } from '../models/pn-l-tag-category-summary-response';
import { scopeCategorySummary } from '../fn/measurement-profiles/scope-category-summary';
import { ScopeCategorySummary$Params } from '../fn/measurement-profiles/scope-category-summary';
import { ScopeCategorySummaryResponse } from '../models/scope-category-summary-response';
import { scopeSummary } from '../fn/measurement-profiles/scope-summary';
import { ScopeSummary$Params } from '../fn/measurement-profiles/scope-summary';
import { ScopeSummaryResponse } from '../models/scope-summary-response';
import { subCategorySummary } from '../fn/measurement-profiles/sub-category-summary';
import { SubCategorySummary$Params } from '../fn/measurement-profiles/sub-category-summary';
import { SubCategorySummaryResponse } from '../models/sub-category-summary-response';
import { yearOnYearCategoryOverview } from '../fn/measurement-profiles/year-on-year-category-overview';
import { YearOnYearCategoryOverview$Params } from '../fn/measurement-profiles/year-on-year-category-overview';
import { YearOnYearCategoryOverviewResponse } from '../models/year-on-year-category-overview-response';
import { yearOnYearIntensityOverview } from '../fn/measurement-profiles/year-on-year-intensity-overview';
import { YearOnYearIntensityOverview$Params } from '../fn/measurement-profiles/year-on-year-intensity-overview';
import { YearOnYearIntensityOverviewResponse } from '../models/year-on-year-intensity-overview-response';
import { yearOnYearOffsetOverview } from '../fn/measurement-profiles/year-on-year-offset-overview';
import { YearOnYearOffsetOverview$Params } from '../fn/measurement-profiles/year-on-year-offset-overview';
import { YearOnYearOffsetOverviewResponse } from '../models/year-on-year-offset-overview-response';
import { yearOnYearScopeOverview } from '../fn/measurement-profiles/year-on-year-scope-overview';
import { YearOnYearScopeOverview$Params } from '../fn/measurement-profiles/year-on-year-scope-overview';
import { YearOnYearScopeOverviewResponse } from '../models/year-on-year-scope-overview-response';

@Injectable({ providedIn: 'root' })
export class MeasurementProfilesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1MeasurementProfilesCategorySummaryPost()` */
  static readonly V1MeasurementProfilesCategorySummaryPostPath = '/v1/measurement-profiles/category-summary';

  /**
   * Category Summary Report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `categorySummary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  categorySummary$Response(params?: CategorySummary$Params, context?: HttpContext): Observable<StrictHttpResponse<CategorySummaryResponse>> {
    return categorySummary(this.http, this.rootUrl, params, context);
  }

  /**
   * Category Summary Report.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `categorySummary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  categorySummary(params?: CategorySummary$Params, context?: HttpContext): Observable<CategorySummaryResponse> {
    return this.categorySummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<CategorySummaryResponse>): CategorySummaryResponse => r.body)
    );
  }

  /** Path part for operation `v1MeasurementProfilesYearOnYearCategoryOverviewPost()` */
  static readonly V1MeasurementProfilesYearOnYearCategoryOverviewPostPath = '/v1/measurement-profiles/year-on-year-category-overview';

  /**
   * Year On Year - Category overview endpoint.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `yearOnYearCategoryOverview()` instead.
   *
   * This method doesn't expect any request body.
   */
  yearOnYearCategoryOverview$Response(params?: YearOnYearCategoryOverview$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<YearOnYearCategoryOverviewResponse>>> {
    return yearOnYearCategoryOverview(this.http, this.rootUrl, params, context);
  }

  /**
   * Year On Year - Category overview endpoint.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `yearOnYearCategoryOverview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  yearOnYearCategoryOverview(params?: YearOnYearCategoryOverview$Params, context?: HttpContext): Observable<Array<YearOnYearCategoryOverviewResponse>> {
    return this.yearOnYearCategoryOverview$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<YearOnYearCategoryOverviewResponse>>): Array<YearOnYearCategoryOverviewResponse> => r.body)
    );
  }

  /** Path part for operation `v1MeasurementProfilesScopeSummaryPost()` */
  static readonly V1MeasurementProfilesScopeSummaryPostPath = '/v1/measurement-profiles/scope-summary';

  /**
   * Scope Summary Report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scopeSummary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  scopeSummary$Response(params?: ScopeSummary$Params, context?: HttpContext): Observable<StrictHttpResponse<ScopeSummaryResponse>> {
    return scopeSummary(this.http, this.rootUrl, params, context);
  }

  /**
   * Scope Summary Report.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scopeSummary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  scopeSummary(params?: ScopeSummary$Params, context?: HttpContext): Observable<ScopeSummaryResponse> {
    return this.scopeSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<ScopeSummaryResponse>): ScopeSummaryResponse => r.body)
    );
  }

  /** Path part for operation `v1MeasurementProfilesScopeCategorySummaryPost()` */
  static readonly V1MeasurementProfilesScopeCategorySummaryPostPath = '/v1/measurement-profiles/scope-category-summary';

  /**
   * Scope Summary Report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scopeCategorySummary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  scopeCategorySummary$Response(params?: ScopeCategorySummary$Params, context?: HttpContext): Observable<StrictHttpResponse<ScopeCategorySummaryResponse>> {
    return scopeCategorySummary(this.http, this.rootUrl, params, context);
  }

  /**
   * Scope Summary Report.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scopeCategorySummary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  scopeCategorySummary(params?: ScopeCategorySummary$Params, context?: HttpContext): Observable<ScopeCategorySummaryResponse> {
    return this.scopeCategorySummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<ScopeCategorySummaryResponse>): ScopeCategorySummaryResponse => r.body)
    );
  }

  /** Path part for operation `v1MeasurementProfilesPnLTagCategorySummaryPost()` */
  static readonly V1MeasurementProfilesPnLTagCategorySummaryPostPath = '/v1/measurement-profiles/pn-ltag-category-summary';

  /**
   * PnL Tag Category Summary.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pnLTagCategorySummary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  pnLTagCategorySummary$Response(params?: PnLTagCategorySummary$Params, context?: HttpContext): Observable<StrictHttpResponse<PnLTagCategorySummaryResponse>> {
    return pnLTagCategorySummary(this.http, this.rootUrl, params, context);
  }

  /**
   * PnL Tag Category Summary.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pnLTagCategorySummary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  pnLTagCategorySummary(params?: PnLTagCategorySummary$Params, context?: HttpContext): Observable<PnLTagCategorySummaryResponse> {
    return this.pnLTagCategorySummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<PnLTagCategorySummaryResponse>): PnLTagCategorySummaryResponse => r.body)
    );
  }

  /** Path part for operation `v1MeasurementProfilesYearOnYearScopeOverviewPost()` */
  static readonly V1MeasurementProfilesYearOnYearScopeOverviewPostPath = '/v1/measurement-profiles/year-on-year-scope-overview';

  /**
   * Year on Year - Scope overview endpoint
   * This will return all active or pending mps yearly scope data of the logged users default company.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `yearOnYearScopeOverview()` instead.
   *
   * This method doesn't expect any request body.
   */
  yearOnYearScopeOverview$Response(params?: YearOnYearScopeOverview$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<YearOnYearScopeOverviewResponse>>> {
    return yearOnYearScopeOverview(this.http, this.rootUrl, params, context);
  }

  /**
   * Year on Year - Scope overview endpoint
   * This will return all active or pending mps yearly scope data of the logged users default company.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `yearOnYearScopeOverview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  yearOnYearScopeOverview(params?: YearOnYearScopeOverview$Params, context?: HttpContext): Observable<Array<YearOnYearScopeOverviewResponse>> {
    return this.yearOnYearScopeOverview$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<YearOnYearScopeOverviewResponse>>): Array<YearOnYearScopeOverviewResponse> => r.body)
    );
  }

  /** Path part for operation `v1MeasurementProfilesYearOnYearOffsetOverviewPost()` */
  static readonly V1MeasurementProfilesYearOnYearOffsetOverviewPostPath = '/v1/measurement-profiles/year-on-year-offset-overview';

  /**
   * Year On Year - Offset overview endpoint.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `yearOnYearOffsetOverview()` instead.
   *
   * This method doesn't expect any request body.
   */
  yearOnYearOffsetOverview$Response(params?: YearOnYearOffsetOverview$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<YearOnYearOffsetOverviewResponse>>> {
    return yearOnYearOffsetOverview(this.http, this.rootUrl, params, context);
  }

  /**
   * Year On Year - Offset overview endpoint.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `yearOnYearOffsetOverview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  yearOnYearOffsetOverview(params?: YearOnYearOffsetOverview$Params, context?: HttpContext): Observable<Array<YearOnYearOffsetOverviewResponse>> {
    return this.yearOnYearOffsetOverview$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<YearOnYearOffsetOverviewResponse>>): Array<YearOnYearOffsetOverviewResponse> => r.body)
    );
  }

  /** Path part for operation `v1MeasurementProfilesYearOnYearIntensityOverviewPost()` */
  static readonly V1MeasurementProfilesYearOnYearIntensityOverviewPostPath = '/v1/measurement-profiles/year-on-year-intensity-overview';

  /**
   * Year on year - Intensity overview endpoint.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `yearOnYearIntensityOverview()` instead.
   *
   * This method doesn't expect any request body.
   */
  yearOnYearIntensityOverview$Response(params?: YearOnYearIntensityOverview$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<YearOnYearIntensityOverviewResponse>>> {
    return yearOnYearIntensityOverview(this.http, this.rootUrl, params, context);
  }

  /**
   * Year on year - Intensity overview endpoint.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `yearOnYearIntensityOverview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  yearOnYearIntensityOverview(params?: YearOnYearIntensityOverview$Params, context?: HttpContext): Observable<Array<YearOnYearIntensityOverviewResponse>> {
    return this.yearOnYearIntensityOverview$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<YearOnYearIntensityOverviewResponse>>): Array<YearOnYearIntensityOverviewResponse> => r.body)
    );
  }

  /** Path part for operation `v1MeasurementProfilesSubCategorySummaryPost()` */
  static readonly V1MeasurementProfilesSubCategorySummaryPostPath = '/v1/measurement-profiles/sub-category-summary';

  /**
   * Category Types.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subCategorySummary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  subCategorySummary$Response(params?: SubCategorySummary$Params, context?: HttpContext): Observable<StrictHttpResponse<SubCategorySummaryResponse>> {
    return subCategorySummary(this.http, this.rootUrl, params, context);
  }

  /**
   * Category Types.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subCategorySummary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  subCategorySummary(params?: SubCategorySummary$Params, context?: HttpContext): Observable<SubCategorySummaryResponse> {
    return this.subCategorySummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<SubCategorySummaryResponse>): SubCategorySummaryResponse => r.body)
    );
  }

  /** Path part for operation `v1MeasurementProfilesImpactSummaryPost()` */
  static readonly V1MeasurementProfilesImpactSummaryPostPath = '/v1/measurement-profiles/impact-summary';

  /**
   * Impact Summary Resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `impactSummary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  impactSummary$Response(params?: ImpactSummary$Params, context?: HttpContext): Observable<StrictHttpResponse<ImpactSummaryResponse>> {
    return impactSummary(this.http, this.rootUrl, params, context);
  }

  /**
   * Impact Summary Resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `impactSummary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  impactSummary(params?: ImpactSummary$Params, context?: HttpContext): Observable<ImpactSummaryResponse> {
    return this.impactSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<ImpactSummaryResponse>): ImpactSummaryResponse => r.body)
    );
  }

  /** Path part for operation `v1MeasurementProfilesElectricityBreakdownPost()` */
  static readonly V1MeasurementProfilesElectricityBreakdownPostPath = '/v1/measurement-profiles/electricity-breakdown';

  /**
   * Electricity Breakdown.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `electricityBreakdown()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  electricityBreakdown$Response(params?: ElectricityBreakdown$Params, context?: HttpContext): Observable<StrictHttpResponse<CategoryBreakdownItemResponsePagedResult>> {
    return electricityBreakdown(this.http, this.rootUrl, params, context);
  }

  /**
   * Electricity Breakdown.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `electricityBreakdown$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  electricityBreakdown(params?: ElectricityBreakdown$Params, context?: HttpContext): Observable<CategoryBreakdownItemResponsePagedResult> {
    return this.electricityBreakdown$Response(params, context).pipe(
      map((r: StrictHttpResponse<CategoryBreakdownItemResponsePagedResult>): CategoryBreakdownItemResponsePagedResult => r.body)
    );
  }

  /** Path part for operation `v1MeasurementProfilesCategoryByEfBreakdownPost()` */
  static readonly V1MeasurementProfilesCategoryByEfBreakdownPostPath = '/v1/measurement-profiles/category-by-ef-breakdown';

  /**
   * Category by Emission Factor Breakdown.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `categoryByEfBreakdown()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  categoryByEfBreakdown$Response(params?: CategoryByEfBreakdown$Params, context?: HttpContext): Observable<StrictHttpResponse<CategoryBreakdownItemResponsePagedResult>> {
    return categoryByEfBreakdown(this.http, this.rootUrl, params, context);
  }

  /**
   * Category by Emission Factor Breakdown.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `categoryByEfBreakdown$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  categoryByEfBreakdown(params?: CategoryByEfBreakdown$Params, context?: HttpContext): Observable<CategoryBreakdownItemResponsePagedResult> {
    return this.categoryByEfBreakdown$Response(params, context).pipe(
      map((r: StrictHttpResponse<CategoryBreakdownItemResponsePagedResult>): CategoryBreakdownItemResponsePagedResult => r.body)
    );
  }

  /** Path part for operation `v1MeasurementProfilesCategoryBySupplierBreakdownPost()` */
  static readonly V1MeasurementProfilesCategoryBySupplierBreakdownPostPath = '/v1/measurement-profiles/category-by-supplier-breakdown';

  /**
   * Category by Supplier Breakdown.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `categoryBySupplierBreakdown()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  categoryBySupplierBreakdown$Response(params?: CategoryBySupplierBreakdown$Params, context?: HttpContext): Observable<StrictHttpResponse<CategoryBreakdownItemResponsePagedResult>> {
    return categoryBySupplierBreakdown(this.http, this.rootUrl, params, context);
  }

  /**
   * Category by Supplier Breakdown.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `categoryBySupplierBreakdown$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  categoryBySupplierBreakdown(params?: CategoryBySupplierBreakdown$Params, context?: HttpContext): Observable<CategoryBreakdownItemResponsePagedResult> {
    return this.categoryBySupplierBreakdown$Response(params, context).pipe(
      map((r: StrictHttpResponse<CategoryBreakdownItemResponsePagedResult>): CategoryBreakdownItemResponsePagedResult => r.body)
    );
  }

}
