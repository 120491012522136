/* eslint-disable no-console */
import {Injectable} from '@angular/core';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {environment} from '../../../../environments/environment';
import {from, Subject} from 'rxjs';
import {fetchAuthSession} from 'aws-amplify/auth';
import {MessageResult} from '../../../api/models/message-result';
import {filter} from 'rxjs/operators';
import {PortalMessageResult} from '../../../api/models/portal-message-result';
import {MessageType} from '../../../api/models/message-type';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private webSocket: WebSocketSubject<MessageResult>;
  messages$ = new Subject<MessageResult>();

  isConnected() {
    return this.webSocket != null && !this.webSocket.closed;
  }

  connect() {
    if (this.webSocket == null) {
      console.info('Connecting websocket');
      const cognitoSession = from(fetchAuthSession());
      cognitoSession.subscribe(user => {
        console.info('Creating websocket instance');
        this.webSocket = webSocket(`${environment.websocketUrl}?Authorization=Bearer ${user?.tokens?.idToken}`);

        this.webSocket
          .pipe(filter(m => (m as PortalMessageResult)?.messageType == MessageType.Portal))
          .subscribe({
            next: (msg) => {
              this.messages$.next(msg);
            },
            error: err => console.error(err),
            complete: () => {
              console.warn('Websocket disconnected. Trying again');
              setTimeout(() => {
                this.webSocket = null;
                console.info('Reconnecting');
                this.connect();
              }, 1000);
            }
          });
      });
    }
  }
}
