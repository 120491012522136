import {Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-enum-selector-button',
  templateUrl: './enum-selector-button.component.html',
  styleUrls: ['./enum-selector-button.component.scss']
})
export class EnumSelectorButtonComponent<T> {
  @Input() formEnumControl: FormControl;
  @Input() allowOptions: EnumOption<T>[] = [];

  public select(value: T) {
    this.allowOptions.forEach(o => {
      o.isSelected = o.value == value;
    });

    this.formEnumControl.setValue(value);
    this.formEnumControl.markAsTouched();
  }
}

export interface EnumOption<T> {
  displayName: string,
  icon: string,
  value: T,
  isSelected: boolean
}
