import {Component, Input, Renderer2, RendererStyleFlags2} from '@angular/core';
import html2canvas from 'html2canvas';
import {UiUtilService} from '../../../services/ui-util.service';
import {GATraceService, TrackingAction} from '../../../services/ga-trace.service';
import {DownloadCategory} from '../../../services/s3-file-object-download.service';

@Component({
  selector: 'app-chart-download-button',
  templateUrl: './chart-download-button.component.html',
  styleUrls: ['./chart-download-button.component.scss']
})
export class ChartDownloadButtonComponent {
  @Input() elementId: string;
  @Input() downloadName: string;

  constructor(private gaTraceService: GATraceService,
              private renderer: Renderer2) {
  }

  // From here https://stackoverflow.com/questions/58921942/html2canvas-grey-background-removal it looks like there is a problem with box shadows
  download() {
    const element = document.getElementById(this.elementId);
    const originalBoxShadow = element.style.boxShadow;
    this.renderer.setStyle(element, 'box-shadow', 'unset', RendererStyleFlags2.Important);
    html2canvas(element).then(canvas => {
      UiUtilService.downloadImageByDomElement(this.downloadName, canvas, 'application/png');
      this.gaTraceService.event(TrackingAction.download, DownloadCategory.Chart, this.elementId);
      this.renderer.setStyle(element, 'box-shadow', originalBoxShadow);
    });
  }
}
