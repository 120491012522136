import { Amplify, fetchAuthSession } from '@aws-amplify/core';
import { assertTokenProviderConfig, AuthAction } from '@aws-amplify/core/internals/utils';
import { forgetDevice as forgetDevice$1 } from '../utils/clients/CognitoIdentityProvider/index.mjs';
import { assertAuthTokens, assertDeviceMetadata } from '../utils/types.mjs';
import { getRegion } from '../utils/clients/CognitoIdentityProvider/utils.mjs';
import '../utils/refreshAuthTokens.mjs';
import '../tokenProvider/errorHelpers.mjs';
import { tokenOrchestrator } from '../tokenProvider/tokenProvider.mjs';
import { getAuthUserAgentValue } from '../../../utils/getAuthUserAgentValue.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/**
 * Forget a remembered device while authenticated.
 *
 * @param input - The ForgetDeviceInput object.
 * @throws - {@link ForgetDeviceException} - Cognito service errors thrown when
 * forgetting device with invalid device key
 * @throws AuthTokenConfigException - Thrown when the token provider config is invalid.
 */
async function forgetDevice(input) {
  const {
    device: {
      id: externalDeviceKey
    } = {
      id: undefined
    }
  } = input ?? {};
  const authConfig = Amplify.getConfig().Auth?.Cognito;
  assertTokenProviderConfig(authConfig);
  const {
    tokens
  } = await fetchAuthSession();
  assertAuthTokens(tokens);
  const deviceMetadata = await tokenOrchestrator.getDeviceMetadata();
  const currentDeviceKey = deviceMetadata?.deviceKey;
  if (!externalDeviceKey) assertDeviceMetadata(deviceMetadata);
  await forgetDevice$1({
    region: getRegion(authConfig.userPoolId),
    userAgentValue: getAuthUserAgentValue(AuthAction.ForgetDevice)
  }, {
    AccessToken: tokens.accessToken.toString(),
    DeviceKey: externalDeviceKey ?? currentDeviceKey
  });
  if (!externalDeviceKey || externalDeviceKey === currentDeviceKey) await tokenOrchestrator.clearDeviceMetadata();
}
export { forgetDevice };
