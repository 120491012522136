import {Component, OnInit} from '@angular/core';
import initAnimation from './animated-logo.component-function';

@Component({
  selector: 'app-animated-logo',
  templateUrl: './animated-logo.component.html',
  styleUrl: './animated-logo.component.scss'
})
export class AnimatedLogoComponent implements OnInit {
  ngOnInit(): void {
    initAnimation();
  }

}
