import {Injectable} from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions,
  FormBuilder,
  FormControl,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {
  noWhiteSpaces,
  numericValidationPattern,
  UniqueCompanyValidator,
  UniqueEmailValidator,
  urlValidationPattern
} from './form-validator.service';
import {
  AvailableProviderResponseExtended
} from '../../portal/components/profile/business/data-collection-hub/models/available-provider-response-extended';
import {EffortRatingType} from '../../../api/models/effort-rating-type';
import {ImpactRatingType} from '../../../api/models/impact-rating-type';
import {InitiativeStatus} from '../../../api/models/initiative-status';
import {EmissionProjectionIntensityType} from '../../../api/models/emission-projection-intensity-type';
import {QuoteChargeBeeItemPriceResponse} from '../../../api/models/quote-charge-bee-item-price-response';
import {PeriodUnit} from '../../../api/models/period-unit';

@Injectable({
  providedIn: 'root'
})
export class TypedFormBuilderService {

  constructor(
    private tfb: FormBuilder,
    private uniqueEmailValidator: UniqueEmailValidator,
    private uniqueCompanyValidator: UniqueCompanyValidator) {
  }

  companyOnBoardForm() {
    return this.tfb.nonNullable.group({
      firstName: ['', [Validators.required, Validators.pattern(noWhiteSpaces)]],
      lastName: ['', [Validators.required, Validators.pattern(noWhiteSpaces)]],
      email: new FormControl<string>('',
        [Validators.required, Validators.email],
        [this.uniqueEmailValidator.validate.bind(this.uniqueEmailValidator)]),
      phone: ['', [Validators.required, Validators.pattern(noWhiteSpaces)]],
      companyName: new FormControl<string>('',
        [Validators.required, Validators.pattern(noWhiteSpaces)],
        [this.uniqueCompanyValidator.validate.bind(this.uniqueCompanyValidator)]),
      website: ['', [Validators.required, Validators.pattern(urlValidationPattern)]],
      industry: [null, [Validators.required]],
      employeeCount: new FormControl<number | null>(null,
        [Validators.required, Validators.min(1), Validators.pattern(numericValidationPattern)])
    });
  }

  partnerCompanyOnBoardForm() {
    return this.tfb.nonNullable.group({
      companyName: new FormControl<string>('',
        [Validators.required, Validators.pattern(noWhiteSpaces)],
        [this.uniqueCompanyValidator.validate.bind(this.uniqueCompanyValidator)]),
      website: ['', [Validators.required, Validators.pattern(urlValidationPattern)]],
      industry: [null, [Validators.required]],
      employeeCount: new FormControl<number | null>(null,
        [Validators.required, Validators.min(1), Validators.pattern(numericValidationPattern)]),
      disclaimer: [false, [Validators.requiredTrue]]
    });
  }

  providerSelectionForm() {
    return this.tfb.nonNullable.group({
      provider: new FormControl<AvailableProviderResponseExtended | null>(null, [Validators.required]),
      anotherProvider: [null]
    });
  }

  authenticationRequestForm() {
    return this.tfb.nonNullable.group({
      accountingFullName: ['', Validators.required],
      accountingEmail: ['', [Validators.required, Validators.email]]
    });
  }

  offsetPaymentForm() {
    return this.tfb.nonNullable.group({
      itemId: ['', Validators.required],
      offsetItemPrice: [null as QuoteChargeBeeItemPriceResponse, Validators.required],
      paymentMethod: ['', Validators.required],
      termsOfServiceAccepted: [false, Validators.required]
    });
  }

  dataPeriodForm() {
    return this.tfb.nonNullable.group({
      closingDate: ['', Validators.required]
    });
  }

  initiativeEditorForm() {
    return this.tfb.nonNullable.group({
      name: ['', Validators.required],
      content: ['', Validators.required],
      category: ['', Validators.required],
      status: new FormControl<InitiativeStatus>(null, Validators.required),
      entity: ['', Validators.required],
      costEffort: new FormControl<EffortRatingType>(null, [Validators.required]),
      impact: new FormControl<ImpactRatingType>(null, Validators.required)
    });
  }

  userForm(editing = false) {
    return this.tfb.group({
      email: [{value: '', disabled: editing}, [Validators.required, Validators.email]],
      firstName: ['', [Validators.required]],
      lastName: ['', []],
      isAdmin: [false],
      isBillingManager: [false],
      isPartnerAdmin: [false]
    });
  }

  companyDetailsUpdateForm() {
    return this.tfb.group({
      companyName: ['', [Validators.required]],
      website: ['', [Validators.required, Validators.pattern(urlValidationPattern)]],
      industry: ['', [Validators.required]],
      employeeCount: [0, [Validators.required, Validators.min(1), Validators.pattern(numericValidationPattern)]],
      companyLogo: [null]
    });
  }

  targetProjectionForm() {
    return this.tfb.group({
      name: ['', [Validators.required]],
      baseline: ['', [Validators.required]],
      targetYear: ['', [Validators.required]],
      reductionAmount: new FormControl<number>(90, [Validators.required]),
      status: [false]
    });
  }

  emissionProjectionForm() {
    return this.tfb.group({
      name: ['', [Validators.required]],
      baseline: ['', [Validators.required]],
      targetYear: ['', [Validators.required]],
      baselineValue: new FormControl<number>(null, [Validators.required]),
      targetValue: new FormControl<number>(null, [Validators.required]),
      emissionProjectionIntensityType: new FormControl<EmissionProjectionIntensityType>(EmissionProjectionIntensityType.Revenue, [Validators.required]),
      unitName: [''],
      status: [false],
      currency: ['AUD']
    });
  }

  selectPlanForm() {
    return this.tfb.group(
      {
        planId: [null as string, [Validators.required]],
        currency: [null as string, [Validators.required]],
        frequency: [null as PeriodUnit, [Validators.required]],
        addOns: this.tfb.array<FormControl<boolean>>([]),
        disableAutoCollect: [false],
        invoiceNotes: [null as string]
      }
    );
  }

  userInformationForm() {
    return this.tfb.group({
      firstName: [null as string, [Validators.required]],
      lastName: [null as string, [Validators.required]],
      email: [{value: '', disabled: true}, [Validators.required, Validators.email]],
    });
  }

  loginForm() {
    return this.tfb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  newPasswordForm() {
    return this.tfb.group({
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]
    }, {
      // Used custom form validator name
      validators: comparePassword('newPassword', 'confirmPassword')
    } as AbstractControlOptions);
  }

  changePasswordWithVerificationCodeForm() {
    return this.tfb.group({
      verificationCode: ['', Validators.required],
      verificationNewPassword: ['', [Validators.required, Validators.minLength(6)]],
      verificationConfirmPassword: ['', Validators.required]
    }, {
      // Used custom form validator name
      validators: comparePassword('verificationNewPassword', 'verificationConfirmPassword')
    } as AbstractControlOptions);
  }

  changePasswordForm() {
    return this.tfb.group({
      currentPassword: [null as string, [Validators.required]],
      newPassword: [null as string, [Validators.required]],
      confirmPassword: [null as string, [Validators.required]],
    }, {
      validators: comparePassword('newPassword', 'confirmPassword')
    } as AbstractControlOptions);
  }

  confirmWithTOPTForm() {
    return this.tfb.group({
      code: [null as string, [Validators.required, Validators.pattern('[\\d+]{6}')]],
      rememberDevice: [false]
    });
  }
}

export function comparePassword(controlName: string, matchingControlName: string): ValidatorFn {
  return (formGroup: AbstractControl) => {
    const control = formGroup.get(controlName);
    const matchingControl = formGroup.get(matchingControlName);

    if (matchingControl.value && control.value === matchingControl.value) {
      matchingControl.setErrors(null);
      return null;
    }
    matchingControl.setErrors({mistMatch: true});
    return {comparePassword: true};
  };
}
