/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AssetItemResponse } from '../models/asset-item-response';
import { currents } from '../fn/asset/currents';
import { Currents$Params } from '../fn/asset/currents';

@Injectable({ providedIn: 'root' })
export class AssetService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1AssetCurrentsGet()` */
  static readonly V1AssetCurrentsGetPath = '/v1/asset/currents';

  /**
   * Get current assets for the current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `currents()` instead.
   *
   * This method doesn't expect any request body.
   */
  currents$Response(params?: Currents$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AssetItemResponse>>> {
    return currents(this.http, this.rootUrl, params, context);
  }

  /**
   * Get current assets for the current user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `currents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  currents(params?: Currents$Params, context?: HttpContext): Observable<Array<AssetItemResponse>> {
    return this.currents$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AssetItemResponse>>): Array<AssetItemResponse> => r.body)
    );
  }

}
