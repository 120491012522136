import {EventEmitter, Injectable} from '@angular/core';
import {MatSort, SortDirection} from '@angular/material/sort';
import {Direction} from '../../../api/models/direction';
import {SortingRequest} from '../../../api/models/sorting-request';
import {merge, Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {PagingRequest} from '../../../api/models/paging-request';
import {
  AsyncMessageDialogComponent
} from '../../portal/components/shared/async-message-dialog/async-message-dialog.component';
import {
  ConfirmationPortalDialogComponent
} from '../../portal/components/shared/confirmation-portal-dialog/confirmation-portal-dialog.component';
import {
  InformationDialogComponent, InformationDialogComponentInput
} from '../../portal/components/shared/information-dialog/information-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class UiUtilService {
  constructor(private dialog: MatDialog) {
  }

  confirmationAction(message: string, callback: (result: boolean) => void) {
    const dialogRef = this.dialog.open(ConfirmationPortalDialogComponent, {
      data: message,
      minWidth: 300,
      maxWidth: 600,
      autoFocus: 'dialog',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (callback) {
        callback(!!result);
      }
    });
  }

  informationDialog(title: string, message: string, callback: () => void) {
    const dialogRef = this.dialog.open(InformationDialogComponent, {
      data: {
        title: title,
        message: message
      } as InformationDialogComponentInput,
      minWidth: 300,
      maxWidth: 600,
      autoFocus: 'dialog'
    });

    dialogRef.afterClosed().subscribe(() => {
      if (callback) {
        callback();
      }
    });
  }

  asyncMessageDialog(message: string) {
    return this.dialog.open(AsyncMessageDialogComponent, {
      data: message,
      disableClose: true,
      maxWidth: '60vw'
    });
  }

  getSortDirection(sortDirection: SortDirection): Direction {
    return (sortDirection.toString() === 'desc' || sortDirection.toString() === '') ? Direction.Desc : Direction.Asc;
  }

  getSortingRequest(sort: MatSort): SortingRequest {
    return {
      sortPropertyName: sort.active,
      sortDirection: this.getSortDirection(sort.direction)
    };
  }

  tableUtilSorting(sort: MatSort, refreshEvent: EventEmitter<any>): Observable<SortingRequest> {
    return merge(sort.sortChange, refreshEvent)
      .pipe(
        startWith({}),
        map(() => {
          return this.getSortingRequest(sort);
        })
      );
  }

  tableUtilPaginating(sort: MatSort, paginator: MatPaginator, refreshEvent: EventEmitter<any>): Observable<[SortingRequest, PagingRequest]> {
    return merge(sort.sortChange, paginator.page, refreshEvent)
      .pipe(
        startWith({}),
        map(e => {
          // This is a no paging event
          if (e === undefined || !('previousPageIndex' in e)) {
            // Reset the paginator when the user re filter or re-short
            paginator.pageIndex = 0;
          }
          const sorting = this.getSortingRequest(sort);
          const paging: PagingRequest = {
            pageIndex: paginator.pageIndex + 1,
            pageSize: paginator.pageSize
          };
          return [sorting, paging];
        })
      );
  }

  static downloadFile(fileName: string, part: Blob, type: string) {
    const link = document.createElement('a');
    link.download = fileName;
    const blob = new Blob([part], {type: type});
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.click();
    window.URL.revokeObjectURL(url);
  }

  static downloadImageByDomElement(fileName: string, element: any, type: string) {
    const link = document.createElement('a');
    link.download = fileName;

    link.href = element.toDataURL(type, 1);
    link.click();
  }
}
