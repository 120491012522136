<div mat-dialog-title>
  <h2>{{initiative?.name}}</h2>
  <span fxFlex></span>
  <mat-icon class="close" fontIcon="icon-cancel" matDialogClose="true"></mat-icon>
</div>
<mat-dialog-content>
  @if (!isLoading) {
    <div fxLayout="column">
      <div fxFlex fxLayout.lt-md="column">
        <div class="margin-bottom-20" fxLayout="column" fxLayoutGap="8px" fxFlex.lt-md="100">
          <div fxLayout="row" fxLayoutAlign="start center">
            <div class="title">Cost:</div>
            <mat-icon class="svg-icon-primary-color" [svgIcon]="EffortIconMapping[initiative.effort]"></mat-icon>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <div class="title">Impact:</div>
            <mat-icon class="svg-icon-accent-color" [svgIcon]="ImpactIconMapping[initiative.impact]"></mat-icon>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <div class="title">Category:</div>
            <div class="category-color-display" [style.background]="initiative.category.baseColorHex"></div>
            {{initiative.category.name}}
          </div>
          @if (initiative.activeCompanyCount > 0) {
            <div fxLayout="row" fxLayoutAlign="start center">
              <div class="title">Popularity:</div>
              <div>
                <mat-icon fontIcon="icon-users"></mat-icon>
                {{initiative.activeCompanyCount}} others doing this
              </div>
            </div>
          }
        </div>
        <span fxFlex></span>
        <div class="margin-bottom-20" fxFlex="40" fxFlex.lt-md="100" fxLayout="column">
          @if (input.reduceActionId) {
            <mat-expansion-panel class="add-decarbonisation-plan" hideToggle>
              <mat-expansion-panel-header fxLayout="row" fxFlexAlign=" center">
                <mat-icon fontIcon="icon-plus"></mat-icon>
                <h5 class="no-margin">
                  Add to Decarbonisation Plan
                </h5>
              </mat-expansion-panel-header>
              <div fxLayout="column">
                <p>Change the status @if (entities?.length > 1) {
                  <span>and entity </span>
                }
                of your initiative to add it to your
              decarbonisation plan.</p>
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Status</mat-label>
                <mat-select [formControl]="statusControl">
                  @for (status of statusValuesAllowed; track status) {
                    <mat-option [value]="status">
                      {{status | enumFormatter}}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
              @if (entities?.length > 1) {
                <mat-form-field appearance="outline" fxFlex>
                  <mat-label>Entity</mat-label>
                  <mat-select [formControl]="entityControl">
                    @for (entity of entities; track entity.id) {
                      <mat-option [value]="entity.id">
                        {{entity.name}}
                      </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              }
              <div fxFlexAlign=" end">
                <button mat-raised-button color="primary"
                  [disabled]="statusControl.invalid || entityControl.invalid"
                  (click)="save()"
                  mat-dialog-close>Save
                </button>
              </div>
            </div>
          </mat-expansion-panel>
        }
      </div>
    </div>
    <mat-divider></mat-divider>
    <mat-accordion  multi="true">
      <mat-expansion-panel class="no-padding-body" expanded>
        <mat-expansion-panel-header class="no-padding">
          <h4 class="no-margin no-line-height">About</h4>
        </mat-expansion-panel-header>
        <quill-view-html class="detail-view" [content]="initiative.content"></quill-view-html>
      </mat-expansion-panel>
      @if (initiative.initiativeActions.length > 0) {
        <mat-divider></mat-divider>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header class="no-padding">
            <h4 class="no-margin no-line-height">Checklist</h4>
          </mat-expansion-panel-header>
          @for (action of initiative.initiativeActions; track action.id) {
            <div>
              @if (!input.reduceActionId) {
                <mat-checkbox
                  color="primary"
                  [checked]="action.done"
                  [value]="action.id"
                  (change)="actionStatusChange($event)">{{action.name}}
                </mat-checkbox>
              }
              @if (input.reduceActionId) {
                <ul>
                  <li>{{action.name}}</li>
                </ul>
              }
            </div>
          }
        </mat-expansion-panel>
      }
      @if (assetCards.length > 0) {
        <mat-divider></mat-divider>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header class="no-padding">
            <h4 class="no-margin no-line-height">Assets</h4>
          </mat-expansion-panel-header>
          <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="24px">
            @for (assetCard of assetCards; track assetCard) {
              <app-asset-card class="asset-card-container" fxFlex="0 0 calc(33.33% - 24px)"
                [asset]="assetCard"
              [downloadCategory]="DownloadCategory.ReduceActionAsset"></app-asset-card>
            }
          </div>
        </mat-expansion-panel>
      }
    </mat-accordion>
  </div>
} @else {
  <app-loading></app-loading>
}
</mat-dialog-content>
