import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {MatSort, SortDirection} from '@angular/material/sort';
import {Observable, Subject, Subscription} from 'rxjs';
import {SortingRequest} from '../../../../../api/models/sorting-request';
import {UiUtilService} from '../../../services/ui-util.service';
import {finalize, switchMap} from 'rxjs/operators';
import {PagingRequest} from '../../../../../api/models/paging-request';
import {MatPaginator} from '@angular/material/paginator';
import {CustomSelectionModel} from '../../../models/custom-selection-model';
import {Clipboard} from '@angular/cdk/clipboard';
import {NotificationService} from '../../../services/notification.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class TableComponent<T extends { id?: string }> implements AfterViewInit, OnInit, OnDestroy {
  isLoading = true;
  list: T[] = [];
  selection = new CustomSelectionModel<T>(true, []);
  paginatedList: any;
  displayedColumnObjects: CustomTableColumn[];
  columnsToDisplay: string[];

  private refreshed: EventEmitter<void> = new EventEmitter();
  private searchSubject$: Subject<SortingRequest> = new Subject<SortingRequest>();
  private searchPagingSubject$: Subject<[SortingRequest, PagingRequest]> = new Subject<[SortingRequest, PagingRequest]>();
  private subscriptions = new Subscription();
  lastVisitedId: string;
  displayIdColumn = false;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() sortActive = '';
  @Input() sortDirection: SortDirection = 'desc';
  @Input() tableOptions: CustomTableColumn[] = [];
  @Input() dataSourceMethod: (sorting: SortingRequest) => Observable<T[]>;
  @Input() dataSourceMethodPaging: (sorting: SortingRequest, paging: PagingRequest) => Observable<any>;
  @Input() templateRow: TemplateRef<any>;
  @Input() activatePaging: boolean = true;
  @Input() enableMultiSelection: boolean = false;
  @Input() pageSize = 10;
  @Input() pageSizeOptions = [10, 20, 50];
  @Input() showFirstLastButtons = true;
  @Input() enableDisplayIdColumn = true;
  @Input() enableRowClick = false;
  @Input() enableMarkRowAsVisited = true;

  @Output() rowClickEvent = new EventEmitter<string>();
  @Output() itemSelectionChanged = new EventEmitter<void>();

  constructor(
    private clipboard: Clipboard,
    private notificationService: NotificationService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private uiUtilService: UiUtilService) {
    this.selection.comparison = (selected, value) => {
      return selected.id === value.id;
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.tableOptions.unshift({
      id: 'id',
      title: 'Id',
      useTemplate: true,
      hide: !this.displayIdColumn
    });
    this.refreshTableOptions();
  }

  ngAfterViewInit(): void {
    if (!this.activatePaging) {
      this.uiUtilService.tableUtilSorting(this.sort, this.refreshed)
        .subscribe(sorting => {
          this.isLoading = true;
          if (!this.searchSubject$.observed) {
            this.createSearchSubscription();
          }
          this.searchSubject$.next(sorting);
        });
    } else {
      this.uiUtilService.tableUtilPaginating(this.sort, this.paginator, this.refreshed)
        .subscribe(tuple => {
          this.isLoading = true;
          if (!this.searchPagingSubject$.observed) {
            this.createSearchPagingSubscription();
          }
          this.searchPagingSubject$.next(tuple);
        });
    }
  }

  refreshTableOptions() {
    const selectColumn = this.tableOptions.find(c => c.id === 'select');
    if (this.enableMultiSelection && selectColumn === undefined) {
      this.tableOptions.unshift({
        id: 'select',
        title: ''
      });
    }

    this.columnsToDisplay = TableComponent.getDisplayedColumnNames(this.tableOptions);
    this.displayedColumnObjects = TableComponent.getDisplayedColumnObjects(this.tableOptions);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    return this.list.filter(p => this.selection.selected.find(s => s.id === p.id) != null).length === this.list.length;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.list.forEach(row => this.selection.deselect(row)) :
      this.list.forEach(row => this.selection.select(row));

    this.itemSelectionChanged.next();
  }

  onChangeSelectionCheckBox(element: T) {
    this.selection.toggle(element);

    this.itemSelectionChanged.next();
  }

  trackByFn(index, item: T) {
    return item.id; // or item.id
  }

  clickRow(row: T) {
    if (this.enableRowClick) {
      this.rowClickEvent.emit(row.id);
    }
    if (row.id) {
      this.lastVisitedId = row.id;
    }
  }

  displayColumnId(display: boolean) {
    this.displayIdColumn = display;
    this.tableOptions.find(o => o.id == 'id').hide = !this.displayIdColumn;
    this.columnsToDisplay = TableComponent.getDisplayedColumnNames(this.tableOptions);
    this.displayedColumnObjects = TableComponent.getDisplayedColumnObjects(this.tableOptions);
  }

  private static getDisplayedColumnObjects(tableOpts: CustomTableColumn[]): CustomTableColumn[] {
    return tableOpts
      // just for config test
      .filter((column: CustomTableColumn) => !column.hide);
  }

  private static getDisplayedColumnNames(tableOpts: CustomTableColumn[]): string[] {
    return tableOpts
      // just for config test
      .filter((column: CustomTableColumn) => !column.hide)
      .map((column: CustomTableColumn) => column.id);
  }

  private createSearchSubscription() {
    const sub = this.searchSubject$.pipe(
      switchMap((sorting) => {
        return this.dataSourceMethod(sorting)
          .pipe(finalize(() => {
            this.isLoading = false;
            this.changeDetectorRef.detectChanges();
          }));
      })
    ).subscribe(result => {
        this.list = result;
      }
    );
    this.subscriptions.add(sub);
  }

  private createSearchPagingSubscription() {
    const sub = this.searchPagingSubject$.pipe(
      switchMap((tuple) => {
        return this.dataSourceMethodPaging(tuple[0], tuple[1])
          .pipe(finalize(() => {
            this.isLoading = false;
            this.changeDetectorRef.detectChanges();
          }));
      })
    ).subscribe(result => {
      this.list = result.items;
      this.paginatedList = result;
    });
    this.subscriptions.add(sub);
  }

  get totalLength(): number {
    return this.paginatedList?.rowCount;
  }

  refresh() {
    this.refreshed.emit();
  }

  detectChanges() {
    this.changeDetectorRef.detectChanges();
  }

  copyToClipboard(text: string) {
    this.clipboard.copy(text);
    this.notificationService.showSuccess(`'${text}' copied to clipboard`);
  }

  exportToCsv(fileName: string) {
    if (!fileName) {
      fileName = 'export.csv';
    }
    // If paging is deactivated we just use the actual items
    // If is activated we make a new request asking for all the items
    if (!this.activatePaging) {
      const csvData = this.convertToCsv(this.list);
      this.downloadCsv(csvData, fileName);
    } else {
      const sortingRequest: SortingRequest = this.uiUtilService.getSortingRequest(this.sort);
      this.dataSourceMethodPaging(sortingRequest, {
        pageSize: this.totalLength,
        pageIndex: 1
      }).subscribe(result => {
        if (result.items) {
          const csvData = this.convertToCsv(result.items);
          this.downloadCsv(csvData, fileName);
        }
      });
    }
  }

  private convertToCsv(data: any[]): string {
    const rows = data.map(row =>
      this.columnsToDisplay.map(fieldName => JSON.stringify(row[fieldName], (_, value) => value ?? '')).join(',')
    );
    const headers = this.displayedColumnObjects.map(col => col.title);
    return [headers.join(','), ...rows].join('\n');
  }

  private downloadCsv(data: string, filename: string) {
    const blob = new Blob([data], {type: 'text/csv'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  }
}

export interface CustomTableColumn {
  title: string;
  id: string;
  sort?: string;
  useTemplate?: boolean,
  hide?: boolean;
  headerWidth?: string;
}
