import {Pipe, PipeTransform} from '@angular/core';
import {CurrentUserResponse} from '../../../api/models/current-user-response';
import {validPartner} from '../../../utils/function-utils';

@Pipe({
  name: 'userValidation'
})
export class UserValidationPipe implements PipeTransform {

  /**
   * Add more validation if they are need it
   * @param user
   * @param validation
   */
  transform(user: CurrentUserResponse, validation: UserValidation = UserValidation.IsValidPartner): boolean {
    if (validation === UserValidation.IsValidPartner) {
      return validPartner(user);
    } else {
      console.warn('Invalid user validation');
      return false;
    }
  }

}

export enum UserValidation {
  IsValidPartner
}
