import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';

@Component({
  selector: 'app-error-message-dialog',
  templateUrl: './error-message-dialog.component.html',
  styleUrls: ['./error-message-dialog.component.scss']
})
export class ErrorMessageDialogComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public input: ErrorMessageDialogInput,
    private snackBarRef: MatSnackBarRef<ErrorMessageDialogComponent>) {
  }

  close() {
    this.snackBarRef.dismiss();
  }
}

export interface ErrorMessageDialogInput {
  title: string;
  details?: string[];
}
