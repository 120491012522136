import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-async-message-dialog',
    templateUrl: './async-message-dialog.component.html',
    styleUrls: ['./async-message-dialog.component.scss']
})
export class AsyncMessageDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public message: string) {
    }
}
