import {Component, OnInit} from '@angular/core';
import initAnimation from './animated-analyst-girl-function';

@Component({
  selector: 'app-animated-analyst-girl',
  templateUrl: './animated-analyst-girl.component.html',
  styleUrl: './animated-analyst-girl.component.scss'
})
export class AnimatedAnalystGirlComponent implements OnInit {
  ngOnInit(): void {
    initAnimation();
  }
}
