/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PortalTokenResult } from '../../models/portal-token-result';

export interface GetPortalToken$Params {
}

export function getPortalToken(http: HttpClient, rootUrl: string, params?: GetPortalToken$Params, context?: HttpContext): Observable<StrictHttpResponse<PortalTokenResult>> {
  const rb = new RequestBuilder(rootUrl, getPortalToken.PATH, 'get');
  if (params) {
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PortalTokenResult>;
    })
  );
}

getPortalToken.PATH = '/v1/payment/get-portal-token';
