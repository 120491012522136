<div class="flex cursor-grab flex-row gap-4 px-4 pt-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle>
  @if (title) {
    <h5 class="!mb-0">{{ title }}</h5>
  } @else {
    <ng-content></ng-content>
  }
  <span class="grow"></span>
  <mat-icon class="close" [mat-dialog-close]="closeResult" fontIcon="icon-cancel" matTooltip="Close"></mat-icon>
</div>
