<div class="message">
  <h6>{{this.input.title}}</h6>
  @if (this.input.details?.length) {
    <ul>
      @for (detail of this.input.details; track detail) {
        <li>{{detail}}</li>
      }
    </ul>
  }
</div>

<div>
  <button mat-raised-button (click)="close()">Close</button>
</div>
