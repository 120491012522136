import {Component, Inject, SecurityContext} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-information-dialog',
  templateUrl: './information-dialog.component.html',
  styleUrl: './information-dialog.component.scss'
})
export class InformationDialogComponent {
  safeHtmlContent: SafeHtml;

  constructor(@Inject(MAT_DIALOG_DATA) public input: InformationDialogComponentInput,
              private sanitizer: DomSanitizer) {
    this.safeHtmlContent = this.sanitizer.sanitize(SecurityContext.HTML, this.input.message);
  }
}

export interface InformationDialogComponentInput {
  title: string,
  message: string
}
