/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CategoryItemResponse } from '../models/category-item-response';
import { getParentCategories } from '../fn/category/get-parent-categories';
import { GetParentCategories$Params } from '../fn/category/get-parent-categories';

@Injectable({ providedIn: 'root' })
export class CategoryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1CategoryGetParentCategoriesGet()` */
  static readonly V1CategoryGetParentCategoriesGetPath = '/v1/category/get-parent-categories';

  /**
   * Get parent categories.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getParentCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  getParentCategories$Response(params?: GetParentCategories$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CategoryItemResponse>>> {
    return getParentCategories(this.http, this.rootUrl, params, context);
  }

  /**
   * Get parent categories.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getParentCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getParentCategories(params?: GetParentCategories$Params, context?: HttpContext): Observable<Array<CategoryItemResponse>> {
    return this.getParentCategories$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CategoryItemResponse>>): Array<CategoryItemResponse> => r.body)
    );
  }

}
