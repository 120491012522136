/* tslint:disable */
/* eslint-disable */
export enum FeatureName {
  XeroAndCsv = 'XeroAndCsv',
  SpendBased = 'SpendBased',
  ActivityBased = 'ActivityBased',
  MultiEntity = 'MultiEntity',
  Scope = 'Scope',
  EfCategory = 'EfCategory',
  EfLabel = 'EfLabel',
  TraceReductionInitiatives = 'TraceReductionInitiatives',
  CreateOwnInitiatives = 'CreateOwnInitiatives',
  TopReductionOpportunities = 'TopReductionOpportunities',
  Prioritisation = 'Prioritisation',
  AllTraceCertifications = 'AllTraceCertifications',
  CsvExport = 'CsvExport',
  SustainabilityReport = 'SustainabilityReport',
  WorkRelatedEmissions = 'WorkRelatedEmissions',
  IndividualFootprint = 'IndividualFootprint',
  ExclusiveContentAndEvents = 'ExclusiveContentAndEvents',
  StaffPresentations = 'StaffPresentations'
}
