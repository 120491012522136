import {Component, Inject, SecurityContext} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-confirmation-portal-dialog',
  templateUrl: './confirmation-portal-dialog.component.html',
  styleUrls: ['./confirmation-portal-dialog.component.scss']
})
export class ConfirmationPortalDialogComponent {
  safeHtmlContent: SafeHtml;

  constructor(
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public message: string
  ) {
    this.safeHtmlContent = this.sanitizer.sanitize(SecurityContext.HTML, message);
  }
}
