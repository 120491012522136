<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center center">
  @for (option of allowOptions; track option) {
    <div class="option"
      [ngClass]="{ 'selected' :option.isSelected }" (click)="select(option.value)"
      fxLayout="column"
      fxLayoutAlign="center center">
      <mat-icon class="icon" [svgIcon]="option.icon"></mat-icon>
      <span>{{option.displayName}}</span>
    </div>
  }
</div>
